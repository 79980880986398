// @flow

import React from 'react';

import BlockList from '../lists/BlockList';
import { italicShipComponent } from '../../utils/string';

import type BookedPackage from '../../models/BookedPackage';

type Props = {
  packages: BookedPackage[],
};

const PackagesList = ({ packages }: Props) => (
  <div className="mt-4">
    <h2>Weitere reservierte Leistungen</h2>

    <p>
      Die korrekte Darstellung der reservierten Leistungen kann einige Minuten dauern. Es kann bis dahin zu einer
      doppelten Darstellung der Leistungsanfrage führen.
    </p>

    <div className="l-block">
      <BlockList>
        {packages.map((pkg, index) => (
          <BlockList.Item link={pkg.link} key={index}>
            {italicShipComponent(`${pkg.itemCount}x ${pkg.displayText}`)}
          </BlockList.Item>
        ))}
      </BlockList>
    </div>
  </div>
);

export default PackagesList;
