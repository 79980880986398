var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import AmexIcon from '../../../../assets/svg/redesign/payment/amex.svg';
import MastercardIcon from '../../../../assets/svg/redesign/payment/mastercard.svg';
import VisaIcon from '../../../../assets/svg/redesign/payment/visa.svg';
import SepaIcon from '../../../../assets/img/redesign/sepa-lastschrift.png';
import classNames from 'classnames';
var paymentName = {
    SEPA: 'EC-Karte / Lastschrift',
    VISA: 'Visa',
    MASTERCARD: 'Mastercard',
    AMEX: 'American Express',
};
var ManifestPaymentOptions = /** @class */ (function (_super) {
    __extends(ManifestPaymentOptions, _super);
    function ManifestPaymentOptions() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ManifestPaymentOptions.prototype.isChecked = function (option) {
        var selected = this.props.selected;
        return selected === option.type;
    };
    ManifestPaymentOptions.prototype.render = function () {
        var _this = this;
        var _a = this.props, options = _a.options, name = _a.name, onChange = _a.onChange, disabled = _a.disabled;
        return (React.createElement("div", { className: "grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-4 mb-4" }, options.map(function (option) {
            var title = paymentName[option.type];
            return (React.createElement("label", { key: option.type, className: classNames('flex flex-col md:aspect-square justify-center items-center gap-1 md:gap-8 p-4 rounded-md cursor-pointer ', _this.isChecked(option)
                    ? 'outline outline-3 outline-ocean-blue-80 bg-ocean-blue-40'
                    : 'bg-white') },
                React.createElement("div", { className: "flex justify-center items-center w-full h-16" },
                    React.createElement(PaymentIcon, { type: option.type })),
                React.createElement("span", { className: "text-md font-bold text-center" }, title),
                React.createElement("input", { type: "radio", className: "hidden", name: name, value: option.type, onChange: function () { return onChange(option.type); }, checked: _this.isChecked(option), disabled: option.disabled || disabled })));
        })));
    };
    return ManifestPaymentOptions;
}(Component));
export default ManifestPaymentOptions;
var PaymentIcon = function (_a) {
    var type = _a.type;
    switch (type) {
        case 'AMEX':
            return React.createElement(AmexIcon, { className: "size-12" });
        case 'MASTERCARD':
            return React.createElement(MastercardIcon, { className: "size-16" });
        case 'VISA':
            return React.createElement(VisaIcon, { className: "size-20" });
        case 'SEPA':
            return (React.createElement("img", { src: SepaIcon, alt: "SEPA Lastschrift", className: "size-full object-contain" }));
    }
};
