// @flow
import React, { Component } from 'react';

import Headline from '../../../components/Headline';
import LinkAsButton from '../../../components/LinkAsButton';
import { inject, observer } from 'mobx-react';
import track from '../../../tracking';
import { Button } from '../../redesign/Button';
import { Link } from 'react-router';
import { Text } from '../../redesign/Text';

type Props = {
  isCheckinSubRoute: boolean,
  memberIndex: Number,
};

@inject('breadcrumbRouterStore')
@observer
export default class ManifestPaymentSuccess extends Component<Props> {
  componentDidMount() {
    // Page View Tracking
    let titles = this.props.breadcrumbRouterStore.getWithoutFirst;
    if (titles) {
      titles.pop();
      track.pageView(window.location.pathname, [
        ...titles,
        'Zahlungsmittel',
        'person-' + this.props.memberIndex,
        'erfolgreich',
      ]);
    }
  }

  render() {
    const { isCheckinSubRoute } = this.props;
    return (
      <div className="page page-manifest-success">
        <div className="l-row">
          <div className="l-col double">
            <Headline title="Vielen Dank für die Hinterlegung Ihrer Zahlungsinformationen." />

            <Text>
              <p>Wir haben Ihre Daten gespeichert.</p>
              <p>
                Bitte beachten Sie, dass die Verarbeitung der Daten einige Minuten dauern kann und in dieser Zeit kein
                Zugriff auf die Daten möglich ist. Nach Beendigung der Verarbeitung können Sie Ihre Daten bei Bedarf bis
                zur Nutzung des Online Check-ins ändern.
              </p>
            </Text>
            <div className="l-right">
              {isCheckinSubRoute ? (
                <Button as={Link} variant="dark" size="lg" to="/checkin">
                  Zur Check-in Übersicht
                </Button>
              ) : (
                <Button as={Link} variant="dark" size="lg" to="/payment">
                  Zur Zahlungsmittel-Übersicht
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
