// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { autobind } from 'core-decorators';

import BreadcrumbLink from '../breadcrumbs/BreadcrumbLink';
import ExcursionInfoList from '../ExcursionInfoList';
import DetailPageLayout from '../DetailPageLayout';
import InfoBox from '../InfoBox';
import BookingAssistant from '../BookingAssistant/ExcursionBookingAssistant';
import WithBookingConfirmation from '../BookingConfirmation/WithBookingConfirmation';
import BulletList from '../lists/BulletList';
import LinkAsButton from '../LinkAsButton';

import InfoBoxSvg from '../InfoBoxSvg';
import BonusIcon from '../../../assets/svg/inline/angebote.inline.svg';
import InfoIcon from '../../../assets/svg/inline/ic-info.inline.svg';

import BookingRequest from '../../models/BookingRequest/ExcursionBookingRequest';
import preload from '../../components/pages/decorators/preload';

import type MasterStore from '../../stores/MasterStore';
import type BookingUiStore from '../../stores/BookingUiStore';
import type ItineraryDay from '../../models/ItineraryDay';
import type Excursion from '../../models/Excursion';
import type Booking from '../../models/Booking';
import { getSlideDirectionString } from '../../utils/sliderHelper';
import { tealiumDateFromString, tealiumIdFromString, tealiumValue } from '../../utils/tracking';
import tracking from '../../tracking';
import isInAppView from '../../utils/isInAppView';

@preload(
  {
    details: 'Excursion',
  },
  false
)
@observer
class ExcursionDetailsBreadcrumb extends Component<{
  details: Excursion,
}> {
  triggered = false;

  render() {
    const { details } = this.props;
    if (!details) {
      return null;
    }

    // console.info('%c Fuck ', 'background: #222; color: #ffff00');

    return <BreadcrumbLink {...this.props} text={details.name} />;
  }
}

type Props = {
  masterStore: MasterStore,
  bookingUiStore: BookingUiStore,
  details: Excursion,
  day: ItineraryDay,
};

@inject('masterStore', 'bookingUiStore', 'breadcrumbRouterStore')
@preload({ details: 'Excursion' })
@preload({ day: 'ItineraryDay' })
@observer
export default class PageExcursionDetails extends Component<Props> {
  static breadcrumb = ExcursionDetailsBreadcrumb;

  bookingRequest: BookingRequest;

  constructor(props: Props) {
    super(props);
    const { bookingUiStore, masterStore, details } = props;

    bookingUiStore.reset();
    this.bookingRequest = new BookingRequest(masterStore.masterData.travelParty, details);
  }

  componentDidMount() {
    setTimeout(() => {
      this.trackPageView();
      this.triggerTracking();
    }, 0);
  }

  trackPageView = () => {
    const { breadcrumbRouterStore, location } = this.props;
    const titles = breadcrumbRouterStore.getWithoutFirst;
    if (titles.length) {
      tracking.pageView(location.pathname, titles, 'product');
    }
  };

  triggered = false;

  triggerTracking() {
    if (this.triggered === false) {
      this.triggered = true;
      tracking.viewItem(this.props.breadcrumbRouterStore, {
        category: this.props.details.analyticsCategory,
        categoryId: tealiumIdFromString(this.props.details.analyticsCategory),
        discount: '0.00',
        code: String(this.props.details.id),
        name: String(this.props.details.name),
        operator: {
          code: this.props.details.operators[0].operatorCode,
          name: this.props.details.operators[0].operatorName,
        },
        quantity: '1',
        startDate: tealiumDateFromString(this.props.details.startDate),
        value: tealiumValue(
          this.props.details.priceTypes.getPrice(
            this.props.masterStore.masterData.requestor.guestType.priceModel,
            false,
            this.props.details.isPriceBulk
          )
        ),
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { masterStore, details } = prevProps;
    const { masterData } = masterStore;

    if (this.props.masterStore.masterData.travelParty !== masterData.travelParty || this.props.details !== details) {
      this.bookingRequest = new BookingRequest(this.props.masterStore.masterData.travelParty, this.props.details);
    }
  }

  @autobind handleBookingCancelClick() {
    const { bookingUiStore } = this.props;
    this.bookingRequest.cancelBooking();
    bookingUiStore.openBookingDialog();
  }

  render() {
    const { day, details, masterStore, bookingUiStore } = this.props;
    const { masterData } = masterStore;
    const { journeyOngoing } = masterData;

    // TUICUNIT-2638: waiting list
    let ifRequestorOnWaitList = false;
    let excursionSubSubTitle = null;
    if (masterData.bookings && masterData.bookings[details.itineraryDate]) {
      const thisBooking = masterData.bookings[details.itineraryDate].filter((item) => item.typeId === details.id);
      const someOnWaitlist = thisBooking.some((i) => i.isWaitListed === true);
      if (thisBooking && thisBooking.length !== 0 && someOnWaitlist) {
        const requestor = masterData.requestor || {};
        thisBooking.forEach((booking) => {
          if (booking.isWaitListed) {
            if (booking.mpis.find((i) => i === requestor.mpi)) {
              ifRequestorOnWaitList = true;
            }
          }
        });
      }

      // TUICUNIT-2687: display time
      if (thisBooking && thisBooking.length !== 0) {
        const bookings: Booking[] = masterData.getBookings(day.date, details.id) || [];

        const { excursionDisplayTime } = bookings[0];

        /* TUICUNIT-3403: deprecated
        const onCruiseOrFirstOrLastDay =
          journeyOngoing || day.isArrival || day.isDeparture;

        excursionSubSubTitle = onCruiseOrFirstOrLastDay
          ? excursionDisplayTime
          : `${excursionDisplayTimenNotOnJourney}${
              excursionDisplayTimenNotOnJourney ? ', ' : ''
            }${details.displayDuration}`;
        */

        excursionSubSubTitle = excursionDisplayTime ? excursionDisplayTime : '';
      }
    }

    return (
      <WithBookingConfirmation bookingRequest={this.bookingRequest}>
        {ifRequestorOnWaitList ? (
          <div className="wait-listed-detail">
            <div className="icon-wrapper">
              <InfoIcon className="inline-svg" />
            </div>
            <div>
              <span className="bold">Hinweis:</span> Sie stehen für diesen Ausflug auf unserer Warteliste
            </div>
          </div>
        ) : null}

        <DetailPageLayout
          day={day}
          model={details}
          onSlideShowChange={(oldIndex, newIndex) => {
            tracking.event(
              'Landausflug',
              `Detailseite_Slider_${getSlideDirectionString(oldIndex, newIndex)}`,
              details.title
            );
          }}
          infoBox={(model) => {
            const percentText = model.percentText;
            return percentText ? (
              <InfoBoxSvg className="desktop-only" icon={<BonusIcon className="inline-svg" />} text={percentText} />
            ) : null;
          }}
          infoList={(model) => {
            const showShortArrivalInfo = model.hasStatusTag('short-text');
            const showLongArrivalInfo = model.hasStatusTag('long-text');
            return (
              <div>
                {(showShortArrivalInfo || showLongArrivalInfo) && (
                  <div className="l-row">
                    <div className="l-col double">
                      <InfoBox noIcon>
                        {showShortArrivalInfo ? (
                          <span>
                            <strong>Hinweis:</strong> Bitte beachten Sie unbedingt Ihre aktuellen Flugzeiten für die
                            Reservierung eines Landausfluges.
                          </span>
                        ) : (
                          <span>
                            <strong>Hinweis:</strong> Bitte beachten Sie bei der Reservierung des Landausfluges Ihre An-
                            und Abreisezeiten, um eine Teilnahme an dem Ausflug gewährleisten zu können. Sollten erneut
                            Kapazitäten verfügbar sein, werden diese hier angezeigt. Außerdem haben Sie ggf. auch an
                            Bord noch die Möglichkeit verfügbare Restplätze zu buchen (Abhängig von der Region,
                            Destination und den lokalen Gegebenheiten).
                          </span>
                        )}
                      </InfoBox>
                    </div>
                  </div>
                )}
                <ExcursionInfoList model={model} />
                {model.isSoldOut ? (
                  <InfoBox noIcon>
                    <strong>Hinweis: </strong>Das Kontingent für diesen Landausflug ist bereits erschöpft. Sollten
                    erneut Kapazitäten verfügbar sein, werden diese hier erneut angezeigt.
                  </InfoBox>
                ) : null}
                {details.pdfLink ? (
                  <InfoBox noIcon>
                    <div>
                      <strong>Wichtiges zu Ihrem Aktiv-Ausflug</strong>
                    </div>
                    <br />
                    <div>Das folgende Dokument:</div>
                    <BulletList
                      bullets={[
                        '<strong>Vor der Reise:</strong> Bitte herunterladen, ausdrucken, durchlesen und ausgefüllt mit an Bord bringen.',
                        '<strong>Während der Reise:</strong> Bitte am Ausflugsschalter ausfüllen.',
                        'Pro Person ist eine unterschriebene Teilnahmebedingung erforderlich.',
                      ]}
                    />
                    <br />
                    <div>
                      Bitte geben Sie das ausgefüllte Dokument nach Ihrer Ankunft auf dem Schiff am Ausflugsschalter ab.
                      <br />
                      <br />
                      Für weitere Informationen wenden Sie sich gerne an unser erfahrenes Team an Bord.
                    </div>
                    <LinkAsButton dark asAnchor={isInAppView} newPage={!isInAppView} link={details.pdfLink}>
                      PDF öffnen
                    </LinkAsButton>
                  </InfoBox>
                ) : null}
              </div>
            );
          }}
          sidebar={(model) => (
            <BookingAssistant
              bookable={model}
              requestorVipOption={masterData.requestorVipOption}
              travelParty={masterData.travelParty}
              bookingRequest={this.bookingRequest}
              onBooking={() => {
                tracking.addToCart(this.bookingRequest.selectedMpis.length, this.bookingRequest.total);
                bookingUiStore.openBookingDialog();
              }}
              onChangeBooking={bookingUiStore.openBookingDialog}
              onCancel={this.handleBookingCancelClick}
              disabledButtonText={model.getBadgeText(masterData.requestorVipOption)}
              buttonSubText={masterData.requestorHasVipOption1 && details.textVip1 ? details.textVip1 : ''}
              showParticipants={
                bookingUiStore.returnedFromConfirmation &&
                this.bookingRequest &&
                this.bookingRequest.selectedMpis.length > 0
              }
              infoBox={(model) => {
                const percentText = model.percentText;
                return percentText ? (
                  <InfoBoxSvg className="mobile-only" icon={<BonusIcon className="inline-svg" />} text={percentText} />
                ) : null;
              }}
            />
          )}
          journeyOngoing={journeyOngoing}
          excursionSubSubTitle={excursionSubSubTitle}
        />
      </WithBookingConfirmation>
    );
  }
}
