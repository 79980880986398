// TODO: add flow types

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import ReactModal from 'react-modal';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import without from 'lodash/without';
import { Dialog } from './redesign/Dialog';
import { Select } from './redesign/Select';
import { Button } from './redesign/Button';

import { CHECKBOX_GROUP_ANY } from '../config/constants';

import { optionsShape } from '../utils/PropTypes';

import MultiSelect from './form/MultiSelect';
import LockBodyScroll from './LockBodyScroll';

@observer
export default class ModalFilter extends Component {
  static propTypes = {
    triggerLabel: PropTypes.string.isRequired,
    label: PropTypes.string,
    anyLabel: PropTypes.string,
    options: optionsShape.isRequired,
    defaultValue: PropTypes.oneOfType([PropTypes.any, PropTypes.arrayOf(PropTypes.any)]),
    multiselect: PropTypes.bool,
    onChange: PropTypes.func,
  };

  state = {
    isOpen: false,
  };

  @observable showModal = false;
  @observable value = [];

  get options() {
    const { anyLabel, options } = this.props;
    if (anyLabel) {
      return [{ value: CHECKBOX_GROUP_ANY, label: anyLabel }, ...options];
    }
    return options;
  }

  ensureArray(val) {
    if (!val) return [];
    if (typeof val !== 'object') return [val];
    return val;
  }

  @autobind
  @action
  open() {
    this.value = [...this.ensureArray(this.props.defaultValue)];
    if (this.props.anyLabel && this.value.length === 0) {
      this.value = [CHECKBOX_GROUP_ANY];
    }
    this.setState({ isOpen: true });
  }

  @autobind
  @action
  close() {
    this.setState({ isOpen: false });
  }

  @autobind
  closeAndApply() {
    if (this.props.onChange) {
      const value = without(this.value, CHECKBOX_GROUP_ANY);
      this.props.onChange(this.props.multiselect ? value.slice() : value[0]);
    }
    this.close();
  }

  @autobind
  @action
  handleChange(key, checked, newValue) {
    if (this.props.multiselect) {
      const value = key === CHECKBOX_GROUP_ANY ? [CHECKBOX_GROUP_ANY] : without(newValue, CHECKBOX_GROUP_ANY);
      if (newValue.length === 0) {
        this.value = [CHECKBOX_GROUP_ANY];
      } else {
        this.value = value;
      }
    } else {
      this.value = checked ? [key] : [CHECKBOX_GROUP_ANY];
    }
  }

  render() {
    const { triggerLabel, label, options, multiselect, anyLabel } = this.props;
    const defaultValue = this.ensureArray(this.props.defaultValue);
    const hasSelection = defaultValue.length > 0 && defaultValue[0] !== CHECKBOX_GROUP_ANY;

    // TODO this was failing to find an object, find out why no element matched (TUICMRL-1158)
    // find returns undefined in case no element matches
    const defaultOption = options.find((o) => o.value === defaultValue[0]) || {};

    const buttonLabel = multiselect || !hasSelection ? triggerLabel : defaultOption.label;

    // filter returns [] on fail
    const selectedValueDisplay = options
      .filter((o) => defaultValue.includes(o.value))
      .map((o) => o.label)
      .join(', ');

    return (
      <div className="modal-wrapper">
        {label ? <div className="modal-trigger-label">{label}</div> : null}
        <Dialog title={label} onClose={() => this.setState({ isOpen: false })} isOpen={this.state.isOpen}>
          <h1>Ihre Auswahl</h1>
          {multiselect ? (
            <p className="modal-info">
              <span className="icon info" />
              Mehrfachauswahl möglich
            </p>
          ) : null}
          <div>
            <MultiSelect options={this.options} value={this.value.slice()} onChange={this.handleChange} />
          </div>
          <fieldset className="flex flex-col md:flex-row gap-2 md:gap-4 mt-4 justify-center">
            <div>
              <Button className="w-full" onClick={this.closeAndApply}>
                Auswahl übernehmen
              </Button>
            </div>
            <div>
              <Button variant="secondary" className="w-full" onClick={this.close}>
                Abbrechen
              </Button>
            </div>
          </fieldset>
        </Dialog>
        <Select
          options={[
            {
              label: selectedValueDisplay || anyLabel,
              value: '',
            },
          ]}
          disabled={!options.length}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();

            e.currentTarget.blur();
            window.focus();
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            if (options.length) {
              this.open();
            }

            return false;
          }}
        />
      </div>
    );
  }
}
