// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';

import LabeledInputBlockColumn from './Column';

const LabeledInputBlock = ({ icon, title, description, children }) => <div className="l-row">{children}</div>;

LabeledInputBlock.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node,
};

LabeledInputBlock.Column = LabeledInputBlockColumn;

export default LabeledInputBlock;
