// @flow
import * as React from 'react';

import { Tile } from './NewTile';
import { Chip } from '../redesign/Chip';

import type PartyMember from '../../models/PartyMember';

import ManifestIcon from '../../../assets/svg/redesign/blog.svg';

type Props = {
  travelParty: PartyMember[],
  partyMember: PartyMember,
  linkBase?: string,
  state?: string,
  configEnabled: boolean,
  configNotification: string,
  onClick: (partyMember: PartyMember, url: string) => void,
};

const ManifestTile = ({
  travelParty,
  partyMember,
  linkBase = '/manifest',
  state,
  configEnabled,
  configNotification,
  onClick,
}: Props) => {
  const index = travelParty.indexOf(partyMember);
  if (index === -1) return null;

  const url = `${linkBase}/manifest/${index}`;

  let disabled = false;
  let badge;
  if (partyMember.manifestPreliminary) {
    badge = <Chip>In Bearbeitung</Chip>;
  } else if (partyMember.manifestCompleted) {
    badge = <Chip mode="success">Vollständig</Chip>;
  } else if (!partyMember.manifestCompleted && !!partyMember.manifestEnabled) {
    badge = <Chip>Unvollständig</Chip>;
  }

  // TUICUNIT-2856 if tilesconfig enable ignore other state and badge
  if (configEnabled === false) {
    disabled = true;
    badge = configNotification;
  }

  return (
    <Tile
      title="Schiffsmanifest"
      icon={ManifestIcon}
      disabled={disabled}
      badge={badge}
      onClick={(e) => {
        e.preventDefault();
        if (onClick) onClick(partyMember, url);
        return false;
      }}
    />
  );
};

export default ManifestTile;
