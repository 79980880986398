// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable, action, computed } from 'mobx';
import { autobind } from 'core-decorators';

import LoadingIndicator from '../LoadingIndicator';
import BreadcrumbLink from '../breadcrumbs/BreadcrumbLink';
import FormSelect from '../form/Select';
import PageLayout from '../Spa/Layout';
import isArray from 'lodash/isArray';
import track from '../../tracking';

import type { StaticContentTypes } from '../../api/staticContent';
import type StaticContentStore, { StaticContentTypeStore } from '../../stores/StaticContentStore';
import type SpaStore, { SpaCategoryStore } from '../../stores/SpaStore';
import type PageContent from '../../models/PageContent';

const HEADLINE_ICON_MAPPING = {
  beauty: 'health-beauty',
  hairdresser: 'hairdresser',
  spa: 'spa-more',
};

@inject('staticContentStore')
@observer
class HealthAndBeautyListBreadcrumb extends Component<{
  staticContentStore: StaticContentStore,
  params: {
    type: StaticContentTypes,
  },
}> {
  @observable title: ?string;

  componentDidMount() {
    this.contentStore.fetch().then(
      action((content) => {
        this.title = content.find((c) => c.type === this.props.params.type).name;
      })
    );
  }

  @computed get contentStore(): StaticContentTypeStore {
    return this.props.staticContentStore.storeForType('healthAndBeautyTeasers');
  }

  render() {
    return <BreadcrumbLink {...this.props} text={this.title || ''} />;
  }
}

type Props = {
  staticContentStore: StaticContentStore,
  spaStore: SpaStore,
  params: {
    type: StaticContentTypes,
  },
  location: { pathname: string },
};

@inject('staticContentStore', 'spaStore', 'breadcrumbRouterStore')
@observer
export default class PageHealthAndBeautyList extends Component<Props> {
  static breadcrumb = HealthAndBeautyListBreadcrumb;

  @observable store: SpaCategoryStore;
  @observable content: ?PageContent;
  @observable selectedCategory: ?string;

  constructor(props: Props) {
    super(props);
    this.store = props.spaStore.storeForCategories('beauty');
  }

  trackPageView = () => {
    const { breadcrumbRouterStore } = this.props;
    const titles = breadcrumbRouterStore.getWithoutFirst;
    track.pageView(window.location.pathname, titles, 'sub-category');
  };

  componentDidMount() {
    setTimeout(() => {
      this.trackPageView();
    }, 0);
    this.contentStore.fetch().then(
      action(() => {
        // TODO: show 404 if that is empty:
        this.content = (this.contentStore.content || []).find((c) => c.type === this.props.params.type);

        if (
          !this.content ||
          !this.content.categoryNames ||
          !isArray(this.content.categoryNames) ||
          !this.content.categoryNames.length
        ) {
          return;
        }
        this.selectedCategory = this.content.categoryNames[0];
        this.store.fetch(this.selectedCategory);
      })
    );
  }

  @computed get contentStore(): StaticContentTypeStore {
    return this.props.staticContentStore.storeForType('healthAndBeautyTeasers');
  }

  @autobind @action selectCategory(category: string) {
    this.selectedCategory = category;
    this.store.fetch(this.selectedCategory);
  }

  @computed get filterOptions(): { label: string, value: string }[] {
    if (!this.content || !this.content.categoryNames || !isArray(this.content.categoryNames)) return [];
    return this.content.categoryNames.map((category) => ({
      label: category,
      value: category,
    }));
  }

  @computed get headlineIcon(): ?string {
    if (!this.content) return;
    return HEADLINE_ICON_MAPPING[this.content.type] || null;
  }

  showFilter() {
    return (
      this.content &&
      this.content.categoryNames &&
      isArray(this.content.categoryNames) &&
      this.content.categoryNames.length > 1
    );
  }

  renderFilter() {
    if (this.showFilter()) {
      return (
        <FormSelect
          name="category"
          label="Wählen Sie aus dem folgenden Angebot:"
          value={this.selectedCategory}
          onChange={this.selectCategory}
          options={this.filterOptions}
        />
      );
    }
  }

  render() {
    if (this.contentStore.isLoading || !this.content) {
      return <LoadingIndicator />;
    }
    return (
      <PageLayout
        headline={this.content.name}
        headlineIcon={this.headlineIcon}
        imageSrc={this.content.imageSrc}
        description={this.content.description}
        defaultHint={!!this.store.hint && !this.showFilter()}
        filter={this.renderFilter()}
        store={this.store}
        linkBase={this.props.location.pathname}
      />
    );
  }
}
