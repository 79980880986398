// @flow
import React from 'react';

import ContentRegulations from './Content';
import { Dialog } from '../../redesign/Dialog';
import Button from '../../Button';

type Props = React.ComponentPropsWithoutRef<typeof ContentRegulations> & {
  btnText?: string,
};

export default function DialogRegulations(props: Props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const { regulations, btnText } = props;

  if (!regulations) return null;

  return (
    <>
      <div className="w-full md:w-auto">
        <Button
          fullWidth
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            setIsOpen(true);

            return false;
          }}
        >
          {btnText}
        </Button>
      </div>
      <Dialog title={regulations.headline} onClose={() => setIsOpen(false)} isOpen={isOpen}>
        <ContentRegulations regulations={regulations} />
      </Dialog>
    </>
  );
}
