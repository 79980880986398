// @flow

const obfuscate = (nr: string, plainLength: number): string => {
  const obfuscatedPart = nr.substr(0, nr.length - plainLength).replace(/./g, '*');
  const plainPart = nr.substr(-plainLength);
  return `${obfuscatedPart}${plainPart}`;
};

export const obfuscateCreditCardNumber = (nr: string = '') => obfuscate(nr, 3);

export const obfuscateIban = (iban: string = '') => obfuscate(iban, 3);
