import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { autobind } from 'core-decorators';

import { bookingRequestShape } from '../../utils/PropTypes';
import track from '../../tracking';

import ItineraryDay from '../../models/ItineraryDay';
import BookingResult from '../../models/BookingResult';

import BookingConfirmation from './index';
import BookingOptions from '../IncludedShoreExBooking/BookingOptions';
import BookingOptionsConfirmation from '../IncludedShoreExBooking/BookingOptionsConfirmation';

@inject('bookingStore', 'bookingUiStore', 'masterStore')
@observer
export default class App extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    day: PropTypes.instanceOf(ItineraryDay),
    bookingStore: PropTypes.object.isRequired,
    bookingUiStore: PropTypes.object.isRequired,
    bookingRequest: bookingRequestShape,
    trackingPath: PropTypes.array,
  };

  componentWillUnmount() {
    this.props.bookingUiStore.cancelBookingOptions();
    this.props.bookingUiStore.cancelBookingDialog();
  }

  @autobind
  handleCloseBookingConfirmation() {
    const { bookingUiStore } = this.props;
    bookingUiStore.cancelBookingDialog();
  }

  @autobind
  handleCloseBookingOptions() {
    const { bookingUiStore } = this.props;
    bookingUiStore.cancelBookingOptions();
  }

  @autobind
  handleCloseBookingOptionsConfirmation() {
    const { bookingUiStore } = this.props;
    bookingUiStore.cancelBookingOptionsConfirm();
  }

  @autobind
  handleSubmitBookingOptions() {
    const { bookingUiStore, bookingRequest } = this.props;
    bookingUiStore.openBookingDialog(bookingRequest);
  }

  @autobind
  handleSubmitBookingDialog() {
    const { bookingStore, bookingRequest, masterStore } = this.props;

    //console.log('SUBMIT BOOKING DIALOG');

    bookingStore.handleBookingRequest(bookingRequest).then((successful) => {
      if (successful) {
        masterStore.reloadMasterData().then(() => {
          try {
            const bookingResult = new BookingResult(
              masterStore.masterData.packages.find(
                (bookedPackage) =>
                  bookedPackage.mpis[0] === bookingRequest.selectedMpi
              )
            );
            track.purchase(bookingResult);
          } catch (e) {
            console.error('Could not extract bookingResult:', e);
          }
        });
      }
    });
  }

  render() {
    const { bookingRequest, bookingUiStore, day } = this.props;

    const { showBookingConfirmation, showBookingOptions, showBookingOptionsConfirm } = bookingUiStore || {};

    if (bookingRequest) {
      if (showBookingOptions) {
        //console.log('BOOKING OPTIONS');

        return (
          <div className="page inclusive-excursion">
            <div className="l-row">
              <div className="l-col large">
                <BookingOptions
                  bookingRequest={bookingRequest}
                  onClose={this.handleCloseBookingOptions}
                  onConfirm={() => {
                    bookingUiStore.openBookingOptionsConfirm();
                  }}
                />
              </div>
            </div>
          </div>
        );
      } else if (showBookingConfirmation) {
        //console.log('BOOKING OVERVIEW');

        // add-to-cart

        return (
          <div className="page">
            <div className="l-row">
              <div className="l-col double">
                <BookingConfirmation
                  day={day}
                  bookingRequest={bookingRequest}
                  onConfirm={this.handleSubmitBookingDialog}
                  onCloseConfirmation={this.handleCloseBookingConfirmation}
                />
              </div>
            </div>
          </div>
        );
      } else if (showBookingOptionsConfirm) {
        //console.log('BOOKING OPTION CONFIRMATION');

        return (
          <div className="page inclusive-excursion">
            <div className="l-row">
              <div className="l-col large">
                <BookingOptionsConfirmation
                  bookingRequest={bookingRequest}
                  onConfirm={this.handleSubmitBookingDialog}
                  onClose={this.handleCloseBookingOptionsConfirmation}
                />
              </div>
            </div>
          </div>
        );
      }
    }

    return this.props.children;
  }
}
