// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';

import Headline from '../../components/Headline';
import LoadingIndicator from '../../components/LoadingIndicator';
import CatalogTile from '../tiles/CatalogTile';

import type PackageStore from '../../stores/PackageStore';
import type TypeStore from '../../stores/OfferOrPackageTypeStore';
import type Package from '../../models/Package';
import type { TrackingExcursion } from '../../types/tracking';
import { tealiumDateFromDateObject, tealiumValue } from '../../utils/tracking';
import tracking from '../../tracking';

type Props = {
  packageStore: PackageStore,
};

@inject('masterStore', 'packageStore', 'breadcrumbRouterStore')
@observer
export default class PageTravelMedia extends Component<Props> {
  static breadcrumb = 'Parkservice';

  componentDidMount() {
    this.store.fetchList();
    setTimeout(() => {
      this.trackPageView();
    }, 0);
  }

  trackPageView = () => {
    tracking.pageView(window.location.pathname, ['parkservice'], 'category');
  };

  @computed
  get store(): TypeStore {
    const { packageStore } = this.props;
    return packageStore.storeForType('parking');
  }

  badgeFor(pkg: Package) {
    if (!pkg.isBookable) return pkg.bookingStatus;
    if (pkg.isBooked) return 'Bereits angefragt';
    return null;
  }

  triggered = false;

  render() {
    if (this.store.isLoading) return <LoadingIndicator />;

    const { headline } = this.store.meta;

    if (this.triggered === false && this.store.items) {
      this.triggered = true;
      const excursions: TrackingExcursion[] = this.store.items.map((item: Package) => ({
        code: item.code,
        name: item.name,
        discount: '0.00',
        startDate: tealiumDateFromDateObject(new Date()),
        value: tealiumValue(String(item.price)),
        quantity: '1',
        operator: {
          code: 'intern',
          name: 'intern-meinschiff',
        },
      }));

      tracking.viewListItem(this.props.breadcrumbRouterStore, excursions);
    }

    return (
      <div className="page parking">
        <Headline title={headline} icon="parking" />
        <div style={{ clear: 'both' }}>&nbsp;</div>

        <div className="l-tiles">
          {this.store.items.map((pkg, index) => (
            <div
              key={pkg.id}
              onClick={() => tracking.setCurrentListItem(index + 1, this.props.breadcrumbRouterStore, {})}
            >
              <CatalogTile
                isBooked={pkg.isBooked}
                isBookedBadge="Bereits angefragt"
                link={pkg.urlExtern || `/parkservice/${pkg.typeId}`}
                imageSrc={pkg.imageSrc}
                title={pkg.title}
                description={pkg.subLine}
                price={pkg.price}
                unit={pkg.priceLabel}
                unavailableBadge={this.badgeFor(pkg)}
                newPage={!!pkg.urlExtern}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

// {teaserText && (
//   <div className="l-row l-mod-sub">
//     <div className="l-col double">
//       <p dangerouslySetInnerHTML={{ __html: teaserText }} />
//     </div>
//   </div>
// )}
// {subline && <h2>{subline}</h2>}
