// @flow
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import includes from 'lodash/includes';
// import ExcursionPrice from './ExcursionPrice';
import ExcursionPriceTypes from './ExcursionPriceTypes';
import ParticipantList from './ParticipantList';
import Price from '../Price';
import Button from '../Button';
import Checkbox from '../form/Checkbox';
import ExcursionMultiSelect from '../Excursion/ExcursionMultiSelect';

import type PartyMember from '../../models/PartyMember';
import type Excursion from '../../models/Excursion';
import type ExcursionBookingRequest from '../../models/BookingRequest/ExcursionBookingRequest';
import { formatPrice } from '../../utils/price';
import type { IVipStatus } from '../../types/bookable';

type Props = {
  bookable: Excursion,
  bookingRequest: ExcursionBookingRequest,
  travelParty: PartyMember[],
  onBooking: ?Function,
  onChangeBooking: ?Function,
  onCancel: ?Function,
  showParticipants: ?Function,
  infoBox: ?Function,
  disabledButtonText: string,
  buttonSubText?: string,
  requestorVipOption?: IVipStatus,
};

type State = {
  showParticipants: ?boolean,
  individualParticipantRemovable: boolean,
};

@observer
export default class ExcursionBookingAssistant extends Component<Props, State> {
  travelPartyState = {};
  initialParticipants = [];

  constructor(props: Props) {
    super(props);

    const { bookingRequest, travelParty, bookable } = props;

    let selectedMpis = bookingRequest.selectedMpis.slice();

    travelParty.forEach((member) => {
      this.travelPartyState[member.mpi] = {
        personHeight: member.personHeight,
        checked: selectedMpis.indexOf(member.mpi) >= 0,
      };
    });
    // TUICUNIT-408 – allow changing reservation if more than 1 participant
    // and only allow removing, not swapping participants
    this.initialParticipants = travelParty.filter((member) => includes(bookable.bookedMpis, member.mpi));

    this.state = {
      individualParticipantRemovable: bookable.isBooked && this.initialParticipants.length > 1,
      showParticipants: props.showParticipants,
    };
  }

  @autobind
  openParticipants() {
    this.setState({ showParticipants: true });
  }

  @autobind
  handleChangeInParty(mpi: string) {
    this.props.bookingRequest.toggleSelection(mpi, this.travelPartyState[mpi].checked);
    this.props.bookingRequest.setPersonHeight(mpi, this.travelPartyState[mpi].personHeight);
  }

  @autobind
  changedCheckbox(value: boolean) {
    try {
      this.props.bookingRequest.setAcceptation(value);
    } catch (exc) {
      // ...
    }
  }

  @autobind
  handleBookingClick() {
    const { onBooking, onChangeBooking, bookable, bookingRequest } = this.props;

    if (bookable.isBooked) {
      if (onChangeBooking) onChangeBooking(bookingRequest);
    } else {
      if (onBooking) onBooking(bookingRequest);
    }
  }

  @autobind
  handleCancelClick() {
    const { onCancel } = this.props;
    if (onCancel) onCancel();
  }

  get furtherOptionsChecked(): boolean {
    const { travelParty } = this.props;

    if (this.personHeightRequired) {
      return (
        travelParty
          .map((member) => {
            if (this.travelPartyState[member.mpi].checked) {
              return !!this.travelPartyState[member.mpi].personHeight;
            }
            return true;
          })
          .indexOf(false) <= -1
      );
    }

    return true;
  }

  get personHeightRequired(): boolean {
    const { bookable } = this.props;
    if (!bookable) return false;
    const { categoryIds } = bookable;
    if (!categoryIds) return false;
    const categoryString = Object.values(categoryIds).join(', ');
    return categoryString.toLowerCase().indexOf('bike') >= 0;
  }

  renderParticipantChoice() {
    const { travelParty, bookable, bookingRequest } = this.props;

    let selectedMpis = bookingRequest.selectedMpis.slice();

    const options = travelParty.map((member) => {
      const checked = selectedMpis.indexOf(member.mpi) >= 0;
      const disabled =
        member._isBaby(member.ageOnDate(bookable.date)) ||
        (this.state.individualParticipantRemovable &&
          !bookable.vacancy && // Do not allow more participants if vacancies are no longer available TUICUNIT-408, SUPTUICMRS-2413
          !this.initialParticipants.find((p) => member.mpi === p.mpi)); // participants

      return {
        value: member.mpi,
        label: member.displayName,
        disabled: disabled,
        checked: checked,
        personHeight: member.personHeight,
      };
    });

    const babiesPresent = !!options.find((m) => m.disabled);

    return (
      <div className="form-checkbox-fakegroup">
        {!bookable.isPriceBulk && (
          <div>
            <h2>Teilnehmer wählen: </h2>
            <ExcursionMultiSelect
              personHeightRequired={this.personHeightRequired}
              bookable={bookable}
              options={options}
              onChange={this.handleChangeInParty}
              handlePersonHeightChange={(mpi, personHeight) => {
                this.travelPartyState[mpi].personHeight = personHeight;
                this.handleChangeInParty(mpi);
              }}
              handlePersonSelectChange={(mpi, checked) => {
                this.travelPartyState[mpi].checked = checked;
                this.handleChangeInParty(mpi);
              }}
            />
          </div>
        )}

        {bookable.exbooking && bookable.exbooking.isSomeWaitListed ? (
          <div className="wait-listed-participant">
            <span className="bold">Hinweis:</span> Die markierten Teilnehmer stehen für diesen Ausflug auf unserer
            Warteliste.
          </div>
        ) : null}

        {bookable.priceBulkText && <p>{bookable.priceBulkText}</p>}

        {babiesPresent ? (
          <p>
            Sofern das Mindestalter dieses Ausflugs erfüllt wird, können Kinder unter 4 Jahren kostenfrei an diesem
            Ausflug teilnehmen. Eine Reservierung für diesen Teilnehmer ist nicht erforderlich.
          </p>
        ) : null}
        {bookable.isExternalOperator && bookable.requiresTourSalesTermsNotice ? (
          <div className="infobox small">
            <Checkbox
              name="operatorAccepted"
              label={bookable.tourSalesTermsText}
              onChange={this.changedCheckbox}
              checked={bookingRequest.acceptedOperator}
            />
          </div>
        ) : null}
        <div className="l-right flex flex-col items-end">
          <ExcursionPriceTypes
            travelParty={travelParty.filter((member) => bookingRequest.selectedMpis.indexOf(member.mpi) >= 0)}
            priceTypes={bookable.priceTypes}
            isPriceBulk={bookable.isPriceBulk}
            date={bookable.date}
            uniqueMode={false}
          />
          <div className="l-mod-sub">
            <Price price={`${formatPrice(bookingRequest.total, true)}`} label="Gesamtpreis" />
          </div>
          <Button
            className="l-mod-sub"
            dark
            big
            disabled={!bookingRequest.isReady || !this.furtherOptionsChecked}
            onClick={this.handleBookingClick}
          >
            {bookable.isBooked ? 'Änderung speichern' : 'Zur Reservierung'}
          </Button>
        </div>
      </div>
    );
  }

  renderDisabledButton() {
    const { disabledButtonText, buttonSubText } = this.props;

    return (
      <div>
        <Button className="l-mod-sub" disabled dark big>
          {disabledButtonText}
        </Button>
        <div className="align-left">{buttonSubText}</div>
      </div>
    );
  }

  // TUICUNIT-2638: waiting list
  renderBookedView() {
    const { travelParty, bookable, requestorVipOption } = this.props;

    return (
      <div className="l-right flex flex-col items-end">
        <ParticipantList travelParty={travelParty} bookable={bookable} />
        {bookable.exbooking && bookable.exbooking.isSomeWaitListed ? (
          <div className="wait-listed-participant">
            <span className="bold">Hinweis:</span> Die markierten Teilnehmer stehen für diesen Ausflug auf unserer
            Warteliste.
          </div>
        ) : null}
        <div className="flex flex-col md:flex-row gap-2">
          {!bookable.isPriceBulk ? (
            bookable.isBookableForRequestor(requestorVipOption) || this.state.individualParticipantRemovable ? (
              <Button
                className="l-mod-sub"
                dark
                big
                onClick={this.openParticipants.bind(this, true)}
                disabled={!bookable.exbooking.cancelable || bookable.exbooking.prelimenary}
              >
                Reservierung ändern
              </Button>
            ) : (
              this.renderDisabledButton()
            )
          ) : null}
          <Button
            onClick={this.handleCancelClick}
            disabled={!bookable.exbooking.cancelable || bookable.exbooking.prelimenary}
          >
            Reservierung stornieren
          </Button>
        </div>
      </div>
    );
  }

  renderUnbookedView() {
    const { bookable, infoBox, requestorVipOption, travelParty } = this.props;

    return (
      <div className="l-right flex flex-col items-end">
        {infoBox ? infoBox(bookable) : null}
        <ExcursionPriceTypes
          travelParty={travelParty}
          priceTypes={bookable.priceTypes}
          isPriceBulk={bookable.isPriceBulk}
          date={bookable.date}
          uniqueMode={true}
        />
        {bookable.isBookableForRequestor(requestorVipOption) ? (
          <Button className="l-mod-sub" dark big onClick={this.openParticipants}>
            Zur Reservierung
          </Button>
        ) : (
          this.renderDisabledButton()
        )}
      </div>
    );
  }

  render() {
    const { bookable } = this.props;

    if (this.state.showParticipants) {
      return this.renderParticipantChoice();
    }

    if (bookable.isBooked) {
      return this.renderBookedView();
    } else {
      return this.renderUnbookedView();
    }
  }
}
