// TODO: add flow types

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import union from 'lodash/union';
import without from 'lodash/without';

import { DIFFICULTY_ANY } from '../config/constants';

import CheckboxGroup from './CheckboxGroup';
import Rating from './Rating';
import { Select } from './redesign/Select';
import { Button } from './redesign/Button';
import { Dialog } from './redesign/Dialog';

@observer
export default class LevelFilter extends Component {
  static propTypes = {
    triggerLabel: PropTypes.string.isRequired,
    label: PropTypes.string,
    options: PropTypes.array.isRequired,
    defaultValue: PropTypes.array,
    onChange: PropTypes.func,
  };

  @observable showModal = false;
  @observable value = [];

  @action.bound
  open() {
    this.showModal = true;
    if (this.value.length === 0) {
      this.value = [DIFFICULTY_ANY];
    } else {
      this.value = [...this.props.defaultValue];
    }
  }

  @action.bound
  close() {
    this.showModal = false;
  }

  @autobind
  closeAndApply() {
    if (this.props.onChange) {
      this.props.onChange(this.value);
    }
    this.close();
  }

  @action.bound
  handleChange(key) {
    const value = this.value;
    const checked = value.includes(key);

    if (key === DIFFICULTY_ANY || (checked === true && value.length === 1)) {
      this.value = [DIFFICULTY_ANY];
    } else if (value[0] === DIFFICULTY_ANY && key !== DIFFICULTY_ANY) {
      this.value = [key];
    } else {
      this.value = checked ? without(value, key) : union(value, [key]);
    }
  }

  render() {
    const { options, triggerLabel, label, defaultValue } = this.props;

    const buttonLabel =
      defaultValue.length === 0 || defaultValue[0] === DIFFICULTY_ANY
        ? triggerLabel
        : options
            .filter((o) => defaultValue.includes(o.value))
            .map((o) => o.label)
            .join(', ');

    return (
      <div className="modal-wrapper">
        {label ? <div className="modal-trigger-label p-0">{label}</div> : null}
        <Dialog title={label} onClose={this.close} isOpen={this.showModal}>
          <h1>Ihre Auswahl</h1>
          <p className="modal-info">
            <span className="icon info" />
            Mehrfachauswahl möglich
          </p>
          <div>
            <CheckboxGroup className="space-y-2">
              {
                // eslint-disable-next-line no-shadow
                options.map(({ value, label }, index) => (
                  <CheckboxGroup.Item
                    key={value}
                    value={value}
                    checked={this.value.includes(value)}
                    onChange={() => this.handleChange(value)}
                    info={value !== DIFFICULTY_ANY ? <Rating value={index - 1} /> : null}
                  >
                    {label}
                  </CheckboxGroup.Item>
                ))
              }
            </CheckboxGroup>
          </div>
          <fieldset className="flex flex-col md:flex-row gap-2 md:gap-4 mt-4 justify-center">
            <div>
              <Button className="w-full" onClick={this.closeAndApply}>
                Auswahl übernehmen
              </Button>
            </div>
            <div>
              <Button variant="secondary" className="w-full" onClick={this.close}>
                Abbrechen
              </Button>
            </div>
          </fieldset>
        </Dialog>
        <Select
          options={[
            {
              label: buttonLabel,
              value: '',
            },
          ]}
          disabled={!options.length}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();

            e.currentTarget.blur();
            window.focus();
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            if (options.length) {
              this.open();
            }

            return false;
          }}
        />
      </div>
    );
  }
}
