import React from 'react';
import { Link } from '../../redesign/Link';
import isInAppView from '../../../utils/isInAppView';
import { Text } from '../../redesign/Text';
var PageHeader = function () {
    return (React.createElement("div", { className: "flex flex-col" },
        React.createElement(Text, null, "Der Online Check-in ist f\u00FCr alle Teilnehmer verpflichtend. Bitte checken Sie rechtzeitig ein, um Ihren gew\u00FCnschten Check-in Zeitraum auszuw\u00E4hlen und am Hafenterminal reibungslos an Bord gehen zu k\u00F6nnen. Der Online Check-in ist bis max. 17 Uhr am Vortag Ihres Kreuzfahrtbeginns verf\u00FCgbar."),
        React.createElement(Text, { className: "mt-4" },
            React.createElement("b", null, "Ben\u00F6tigen Sie Hilfe?"),
            React.createElement("br", null),
            React.createElement(Link, { href: "https://www.meinschiff.com/faq/an-und-abreise?id=133", target: isInAppView ? '_self' : '_blank', className: "text-ocean-blue-120" }, "H\u00E4ufige Fragen zum Online Check-in"))));
};
export default PageHeader;
