/**
 * Map of values in page_name parameters.
 *    The left side is the old raw value.
 *    The right side is the replacement that will be inserted.
 */
export const PAGE_NAME_REPLACEMENT_MAP = {
  '|online-check-in-uebersicht': '|online-checkin',
  '|schiffsmanifest': '|schiffsmanifest|startseite',

  // '|schoenheit-gesundheit': '|schoenheit-gesundheit|startseite',
};
