import React from 'react';
import { twMerge } from 'tailwind-merge';
export var Chip = function (_a) {
    var _b = _a.mode, mode = _b === void 0 ? 'default' : _b, children = _a.children;
    return (React.createElement("div", { className: twMerge('flex-row items-center px-3 py-2 rounded-full flex-none order-1 flex-grow-0 text-[12px] text-high-emphasis font-semibold', mode === 'default' && 'bg-gray-200', mode === 'warning' && 'bg-yellow-200', mode === 'danger' && 'bg-red-200', mode === 'success' && 'bg-green-200') }, children));
};
/*
display: flex;
flex-direction: row;
align-items: center;
padding: 8px 12px;

width: 139px;
height: 28px;

background: #FFF1DB;
border-radius: 9999px;

flex: none;
order: 1;
flex-grow: 0;

*/
