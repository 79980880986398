import React from 'react';
import { twJoin, twMerge } from 'tailwind-merge';
/**
 * An alert gives people critical information they need right away.
 *
 * Choose the appropriate alert type and size based on the content, the context and the urgency of information.
 *
 * ## Usage
 *
 * In the simplest possible scenario you should provide a `mode` property to render the appropriate alert type and of course some `children` to render the content.
 *
 * ```tsx
 *  <Alert mode="positive">
 *    Your data was saved.
 * </Alert>
 * ```
 *
 * Add a headline to the alert by providing a `headline` property and adjust the visual size of the alert by providing a `size` property.
 *
 * ```tsx
 * <Alert mode="error" headline="An error occurred" size="sm">
 *   Please try again.
 * </Alert>
 * ```
 *
 * In a more sophisticated scenario you can also add links to headlines and children. You're also free to specify the HTML element that is used to render the alert.
 *
 * ```tsx
 *  const retry = () => {
 *    // ...
 *  };
 *
 *  return (
 *    <Alert
 *      mode="error"
 *      as="div"
 *      headline={(
 *        <>
 *          Error <a href="#">#1234</a> occurred.
 *        </>
 *      )}
 *    >
 *      Please <button onClick={() => retry()}>try again</button>.
 *    </Alert>
 *  );
 * ```
 */
export var Alert = function (_a) {
    var children = _a.children, _b = _a.mode, mode = _b === void 0 ? 'warning' : _b, _c = _a.size, size = _c === void 0 ? 'lg' : _c, icon = _a.icon, headline = _a.headline, cta = _a.cta, className = _a.className;
    return (React.createElement("div", { "data-tailwind": true, className: twMerge('space-y-2 rounded-md border border-solid p-6', mode === 'error' && 'border-warning-120 bg-warning-20 text-warning-120', mode === 'positive' &&
            'border-success-100 bg-success-20 text-success-120', mode === 'neutral' && 'border-marine-20 bg-marine-10 text-marine-100', mode === 'warning' &&
            'border-attention-120 bg-attention-20 text-attention-120', className) },
        headline && (React.createElement("div", { className: "flex items-center gap-2" }, headline && (React.createElement(React.Fragment, null,
            icon,
            React.createElement("div", { className: twMerge('flex-1', 'font-semibold text-h3') }, headline))))),
        (children || cta || !headline) && (React.createElement("div", { className: twJoin('flex', icon && headline && 'md:pl-8') }, (children || cta) && (React.createElement("div", { className: "flex-1 space-y-4" },
            children && React.createElement("div", { className: "text-md" }, children),
            cta && React.createElement("div", null, cta)))))));
};
