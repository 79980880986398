// TODO: add flow types

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import browserHistory from 'react-router/lib/browserHistory';
import { routerShape } from 'react-router/lib/PropTypes';
import { inject, observer } from 'mobx-react';
import { autobind } from 'core-decorators';

import tracking from '../tracking';
import { LayoutHeader } from './redesign/Layout/Header';

@inject('masterStore', 'authStore')
@observer
export default class Header extends Component {
  static propTypes = {
    masterStore: PropTypes.object.isRequired,
    authStore: PropTypes.object.isRequired,
  };

  static contextTypes = {
    router: routerShape,
  };

  @autobind onBackClick(event) {
    event?.preventDefault();

    browserHistory.goBack();
  }

  @autobind onLogoutClick() {
    this.props.authStore.logout().then(() => {
      // We make a hard reset here, to be sure, we don't have any states saved in some stores
      // or anywhere else
      window.location.href = this.props.authStore.user.backLink ? this.props.authStore.user.backLink : '/login';
    });
  }

  get isLoggedIn() {
    const { user } = this.props.masterStore;
    // a logged-in user must exist and have an API token
    return !!user || (user && !!user.apiToken);
  }

  render() {
    const toLink = window.location.pathname.indexOf('maintenance') >= 0 ? '/maintenance' : '/';
    const hasBacklink = !!this.props.authStore.user && !!this.props.authStore.user.backLink;

    return (
      <LayoutHeader
        onClickBack={this.onBackClick}
        onClickLogout={this.onLogoutClick}
        logoProps={{
          href: hasBacklink ? this.props.authStore.user.backLink : toLink,
          onClick: () => {
            if (hasBacklink) {
              tracking.click('navi.icon.logout');
            }
          },
        }}
      />
    );
  }
}
