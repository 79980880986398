// @flow

import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { inject, observer } from 'mobx-react';
import { action, computed, observable } from 'mobx';

import preload from '../../../components/pages/decorators/preload';

import WithBookingConfirmation from '../../BookingConfirmation/WithBookingConfirmation';
import Headline from '../../Headline';
import HeroImage from '../../HeroImage';
import InfoBox from '../../InfoBox';
import LinkAsButton from '../../LinkAsButton';
import BreadcrumbLink from '../../breadcrumbs/BreadcrumbLink';
import SpaBalconyCabinBookingAssistant from '../../BookingAssistant/SpaBalconyCabinBookingAssistant';
import BookingList from '../../BookingList/SpaOffer';
import BulletList from '../../lists/BulletList';
import DayChooser from '../../DayChooser';

import ItineraryDayModel from '../../../models/ItineraryDay';
import SpaBookingRequest from '../../../models/BookingRequest/SpaBookingRequest';
import SpaOfferInclusive from '../../../models/SpaBalconyCabin/SpaOfferInclusive';

import type MasterStore from '../../../stores/MasterStore';
import type BookingUiStore from '../../../stores/BookingUiStore';
import type SpaBalconyCabinStore from '../../../stores/SpaBalconyCabinStore';
import type Booking from '../../../models/Booking';
import { tealiumDateFromString, tealiumIdFromString, tealiumValue } from '../../../utils/tracking';
import tracking from '../../../tracking';
import isInAppView from '../../../utils/isInAppView';

@inject('masterStore')
@preload({ details: 'SpaBalconyCabin' }, false)
@observer
class SpaDetailsBreadcrumb extends Component<{
  masterStore: MasterStore,
  details: SpaOfferInclusive,
}> {
  render() {
    const { details, masterStore } = this.props;
    if (!details || !masterStore || !masterStore.masterData) return null;

    return <BreadcrumbLink {...this.props} text={details.headline} />;
  }
}

type Props = {
  masterStore: MasterStore,
  bookingUiStore: BookingUiStore,
  spaBalconyCabinStore: SpaBalconyCabinStore,
  details: SpaOfferInclusive,
  day: ?ItineraryDayModel,
  params: { packageId: string },
};

@inject('masterStore', 'bookingUiStore', 'spaBalconyCabinStore', 'breadcrumbRouterStore')
@preload({ details: 'SpaBalconyCabin' })
@preload({ day: 'ItineraryDay' }, false, true)
@observer
export default class PageSpaBalconyCabinDetails extends Component<Props> {
  static breadcrumb = SpaDetailsBreadcrumb;

  @observable bookingRequest: SpaBookingRequest;

  @action componentDidMount() {
    const { details, masterStore, day, spaBalconyCabinStore } = this.props;
    if (!details || !masterStore || !masterStore.masterData) return null;

    spaBalconyCabinStore.fetchSpaTreatmentStatusList(details.spaId);

    this.bookingRequest = new SpaBookingRequest(masterStore.masterData.travelParty, details);
    if (day) {
      this.bookingRequest.chooseDay(day);
    }
    setTimeout(() => {
      this.trackPageView();
    }, 0);
  }

  trackPageView() {
    if (this.props.details && this.props.details.title) {
      tracking.pageView(window.location.pathname, ['spa-balkonkabine', this.props.details.title], 'product');
    }
  }

  triggerTracking() {
    tracking.viewItem(this.props.breadcrumbRouterStore, {
      category: this.props.details.analyticsCategory,
      categoryId: tealiumIdFromString(this.props.details.analyticsCategory),
      discount: '0.00',
      code: this.props.details.id,
      name: this.props.details.title,
      operator: {
        code: 'intern',
        name: 'intern-meinschiff',
      },
      quantity: '1',
      startDate: tealiumDateFromString(this.bookingRequest.selectedDay.date),
      value: tealiumValue(String(this.props.details.price)),
    });
  }

  @computed get isBookedForUser(): boolean {
    const { details } = this.props;
    return details.isBooked && details.statusCode === 6;
  }

  @computed get isBookedForParticipant(): boolean {
    const { spaBalconyCabinStore } = this.props;
    if (!spaBalconyCabinStore) return false;

    const { spaTreatmentStatusList } = spaBalconyCabinStore;
    return !!spaTreatmentStatusList && spaTreatmentStatusList.list.some(({ statusCode }) => statusCode === 6);
  }

  @computed get isBookedForTravelParty(): boolean {
    const { spaBalconyCabinStore } = this.props;
    if (!spaBalconyCabinStore) return false;

    const { spaTreatmentStatusList } = spaBalconyCabinStore;
    return !!spaTreatmentStatusList && spaTreatmentStatusList.list.every(({ statusCode }) => statusCode === 6);
  }

  @computed get bookings(): Booking[] {
    // @TODO: check if this method is really needed. Smells funny
    const { masterStore, params } = this.props;
    if (!this.bookingRequest || !this.bookingRequest.selectedDay) {
      return [];
    }
    return masterStore.masterData
      .getBookings(this.bookingRequest.selectedDay.date)
      .filter((booking) => booking.typeId === parseInt(params.packageId, 10));
  }

  @action.bound openAssistantWithDay(day: ItineraryDayModel) {
    this.bookingRequest.chooseDay(day);
    this.triggerTracking();
    window.scrollTo(0, 0);
  }

  @autobind handleBookingCancelClick(booking: Booking) {
    this.bookingRequest.cancelBooking(booking);
    this.props.bookingUiStore.openBookingDialog();
  }

  render() {
    const {
      params,
      details, // package/priv/spa/detail/
      masterStore,
      bookingUiStore,
      spaBalconyCabinStore,
    } = this.props;
    const { masterData, user } = masterStore;
    const { bookingRequest, handleBookingCancelClick, isBookedForTravelParty, openAssistantWithDay } = this || {};

    if (!user || !details || !masterStore || !masterStore.masterData) {
      return null;
    }

    return (
      <WithBookingConfirmation bookingRequest={bookingRequest}>
        <div className="page page-spa-balcony-cabin">
          <Headline title={details.headline} />

          <HeroImage src={details.imageSrc} alt="tets" className="l-mod-sub" />

          <div className="l-row">
            <div className="l-col double">
              <p>{details.description}</p>
              <br />
              {masterData.cruiseIsRunning && (
                <div>
                  <h2>Reservierung & Stornierung</h2>
                  <p>
                    Bitte beachten Sie, dass ab Beginn Ihrer Reise die Inklusivleistungen der SPA Balkonkabine nur noch
                    an der SPA & Meer Rezeption reserviert oder storniert werden können.
                  </p>
                </div>
              )}
              <BookingList
                header="Bereits reservierte Termine für diese Inklusivleistung:"
                copy="Bitte beachten Sie, dass Sie für diese Leistung Ihrer SPA Balkonkabine bereits eine Reservierung vorgenommen haben. Inklusivleistungen Ihrer SPA Balkonkabine können nur einmalig pro Person reserviert werden."
                bookings={masterData.getBookingsByTypeId(+params.packageId)}
                masterData={masterData}
                onCancelBooking={handleBookingCancelClick}
              />

              {details.hint ? (
                <p>
                  <strong>Besonderer Hinweis:</strong> {details.hint}
                </p>
              ) : null}
              {details.minimumAge ? (
                <p>
                  <strong>Mindestalter:</strong> {details.minimumAge} Jahre
                </p>
              ) : null}
              {details.pdfLink ? (
                <InfoBox noIcon>
                  <BulletList
                    title="Wichtiges vor der Anwendung"
                    bullets={[
                      'Laden Sie das PDF-Dokument herunter und füllen Sie es aus, so können wir Ihre individuellen Bedürfnisse bei der entsprechenden Leistung berücksichtigen',
                      'Am Anreisetag geben Sie das bereits ausgedruckte und unterschriebene Haftungsdokument an der SPA & Meer Rezeption (bei SPA-Anwendungen) oder im Bereich Sport & Gesundheit (bei Fitnessleistungen) ab oder füllen die Fragebögen direkt vor Ort aus',
                      'Es gelten die allgemeinen SPA & Sport Stornierungsbedingungen',
                    ]}
                  />

                  <LinkAsButton dark asAnchor={isInAppView} newPage={!isInAppView} link={details.pdfLink}>
                    PDF herunterladen
                  </LinkAsButton>
                </InfoBox>
              ) : null}
            </div>
            <div className="l-col right l-right">
              {bookingRequest ? (
                <SpaBalconyCabinBookingAssistant
                  bookable={details}
                  travelParty={masterData.travelParty}
                  bookingRequest={bookingRequest}
                  onBooking={() => {
                    tracking.addToCart(this.bookingRequest.selectedMpis.length, this.bookingRequest.total);
                    bookingUiStore.openBookingDialog();
                  }}
                  cruiseIsRunning={masterData.cruiseIsRunning}
                  spaTreatmentStatusList={spaBalconyCabinStore.spaTreatmentStatusList}
                />
              ) : null}
            </div>
          </div>

          {!isBookedForTravelParty &&
          !masterData.cruiseIsRunning &&
          (!bookingRequest || !bookingRequest.selectedDay) ? (
            <DayChooser
              // vacancy list comes from itinerary.list, not from details. see this.props.masterStore.reloadItinerary();
              model={details}
              title="Bitte wählen Sie den Tag aus, an dem Sie die Anwendung reservieren möchten:"
              onSelect={openAssistantWithDay}
            />
          ) : null}
        </div>
      </WithBookingConfirmation>
    );
  }
}
