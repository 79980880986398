// @flow
import * as React from 'react';
import { observer } from 'mobx-react';

import Headline from '../../../components/Headline';
import InfoList from '../../../components/InfoList';
import { Button } from '../../../components/redesign/Button';
import LoadingIndicator from '../../../components/LoadingIndicator';
import PaymentSummary from '../../../components/CheckIn/Process/PaymentSummary';

import type CheckInRequest from '../../../models/CheckInRequest';
import type PartyMember from '../../../models/PartyMember';
import type PaymentInfo from '../../../models/PaymentInfo';

type Props = {
  checkInRequest: CheckInRequest,
  membersReadyToCheckIn: PartyMember[],
  travelParty: PartyMember[],
  groupBooking: {
    status: boolean,
    content: string,
  },
  onSubmit: () => mixed,
  onResetCheckIn: () => mixed,
  paymentInfos: PaymentInfo[],
  paymentInfosLoaded: boolean,
};

const getArrivalAtNote = (
  mpi: number,
  checkInRequest: CheckInRequest,
  member: PartyMember,
  isGroupBooking: boolean
): ?string => {
  const selectedMember = checkInRequest.requestData.selectedMembers.find((m) => m.mpi === mpi);

  if (!selectedMember) return null;

  /* TUICUNIT-3368: Check-in for Groups (@_@) */
  if (isGroupBooking) {
    return null;
  }

  if (member.hasTuicTravelToShip === true) {
    return { timeInfo1: 'Anreise findet mit TUI Cruises statt' };
  }

  if (selectedMember.timeSlote && selectedMember.timeSlote.state) {
    return {
      timeInfo1: `Check-in Zeitfenster: ${selectedMember.timeSlote.display}`,
    };
  }

  let fallbackDisplayTimeSlote = null;
  if (checkInRequest.backUpData) {
    fallbackDisplayTimeSlote = checkInRequest.backUpData.find((m) => m.mpi === mpi);
  }

  if (fallbackDisplayTimeSlote && fallbackDisplayTimeSlote.timeSlote) {
    return {
      timeInfo1: `Check-in Zeitfenster: ${fallbackDisplayTimeSlote.timeSlote.display}`,
    };
  }

  if (!selectedMember.arrivalAt || !selectedMember.arrivalAt.where || !selectedMember.arrivalAt.time) {
    return null;
  }
  const mapping = {
    airport: 'Flughafen',
    port: 'Hafen',
  };
  const where = mapping[selectedMember.arrivalAt.where];
  if (!where) return null;
  return {
    timeInfo1: `Ankunftsort: ${where}`,
    timeInfo2: `Ankunftszeit: ${selectedMember.arrivalAt.time} Uhr`,
  };
};

const CheckInProcessSummary = observer(
  ({
    checkInRequest,
    membersReadyToCheckIn,
    travelParty,
    groupBooking,
    onSubmit,
    onResetCheckIn,
    paymentInfos,
    paymentInfosLoaded,
  }: Props) => {
    /* TUICUNIT-3368: Check-in for Groups (@_@) */
    checkInRequest.setGroupBooking(groupBooking.status);
    return (
      <div className="flex flex-col gap-8">
        <div>
          <Headline title="Online Check-in: Übersicht Ihrer Daten" />
          {checkInRequest.sendError && <p className="error-message">{checkInRequest.sendError}</p>}
          <p>
            Prüfen Sie bitte in der folgenden Ansicht Ihre eingegeben Daten und finalisieren Sie Ihren Online Check-in.
            Bitte beachten Sie, dass nach erfolgtem Online Check-in Ihre Daten nicht mehr änderbar sind.
          </p>
        </div>

        <div className="flex flex-col">
          <h2>Ihre Kontaktdaten</h2>
          <InfoList
            infos={[
              {
                key: 'E-Mail-Adresse',
                value: checkInRequest.requestData.email,
              },
              {
                key: 'Mobilnummer',
                value: checkInRequest.requestData.mobile || '–',
              },
            ]}
          />
          <h2>Teilnehmer, die online eingecheckt werden:</h2>
          <ul>
            {membersReadyToCheckIn
              .filter((member) =>
                checkInRequest.requestData.selectedMembers.some((selectedMember) => member.mpi === selectedMember.mpi)
              )
              .map((member) => {
                const arrivalAtNote = getArrivalAtNote(member.mpi, checkInRequest, member, groupBooking.status);

                return (
                  <li key={member.mpi}>
                    <span className="text-xl mx-3">•</span>
                    <span className="pr-1">{member.displayName}</span>
                    <span>
                      {arrivalAtNote && arrivalAtNote.timeInfo1 && (
                        <span className="note1">{arrivalAtNote.timeInfo1}</span>
                      )}
                      {arrivalAtNote && arrivalAtNote.timeInfo2 && (
                        <span className="note2">{arrivalAtNote.timeInfo2}</span>
                      )}
                    </span>
                  </li>
                );
              })}
          </ul>
          <div className="flex justify-end">
            <Button onClick={onResetCheckIn} variant="secondary">
              Ändern
            </Button>
          </div>
        </div>
        <hr />
        <div>
          <PaymentSummary
            paymentInfos={paymentInfos}
            paymentInfosLoaded={paymentInfosLoaded}
            travelParty={travelParty}
          />
        </div>
        <hr />
        <div className="flex justify-end">
          {checkInRequest.isSending ? (
            <LoadingIndicator />
          ) : (
            <Button big dark onClick={onSubmit}>
              Jetzt online einchecken
            </Button>
          )}
        </div>
      </div>
    );
  }
);

export default CheckInProcessSummary;
