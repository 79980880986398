
/**
 * All paths, that should not be tracked automatically, should be listed here in regex format.
 * Manual tracking would then be necessary to apply page view tracking.
 */
export const BLACKLISTED_PATHS: string[] = [
  /^\/highlights\/.+/gm,

  // /^\/manifest\/manifest\/.+/gm,
  // /^\/manifest\/payment\/.+/gm,
  // /^\/manifest\/health\/.+/gm,

  // /^\/checkin\/manifest\/.+/gm,
  // /^\/checkin\/payment\/.+/gm,
  // /^\/checkin\/health\/.+/gm,

  // Product pages
  // /^\/schoenheit-und-gesundheit\/[a-zA-ZöäüßÖÄÜ]+\/[0-9]+/gm,
  // /^\/schoenheit-und-gesundheit\/[a-zA-ZöäüßÖÄÜ]+\/[a-zA-ZöäüßÖÄÜ]+\/[0-9]+/gm,
  /^\/fitness\/[a-zA-ZöäüßÖÄÜ]+\/[0-9]+\/.+/gm,
  // /^\/landausfluege\/[0-9a-zA-ZöäüßÖÄÜ]+\/[0-9a-zA-ZöäüßÖÄÜ]+/gm,
];
