// @flow
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import LoadingIndicator from '../../../components/LoadingIndicator';
import View from '../../../components/Manifest/Regulations/View';
import track from '../../../tracking';

import type ManifestStore from '../../../stores/ManifestStore';
type Props = {
  manifestStore: ManifestStore,
};

@inject('manifestStore', 'breadcrumbRouterStore')
@observer
export default class PageManifestRegulations extends Component<Props> {
  static breadcrumb = 'Einreisebestimmungen';

  componentDidMount() {
    this.props.manifestStore.fetchRegulations();
    setTimeout(() => {
      this.trackPageView();
    }, 0);
  }

  trackPageView = () => {
    const { breadcrumbRouterStore } = this.props;
    const titles = breadcrumbRouterStore.getWithoutFirst;

    if (titles.length) {
      track.pageView(window.location.pathname, titles, 'content');
    }
  };

  render() {
    const { regulations } = this.props.manifestStore;
    if (!regulations) return <LoadingIndicator />;
    return <View regulations={regulations} />;
  }
}
