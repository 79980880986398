// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';

import { Input } from '../redesign/Input';

class FormInput extends React.Component {
  static propTypes = {
    autoComplete: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    // Number of characters user can enter
    maxLength: PropTypes.number,
    // If trueish we show the number of chars left to enter
    showRemaining: PropTypes.bool,
    mandatory: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    error: PropTypes.string,
    hasError: PropTypes.bool,
    trim: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  };

  static contextTypes = {
    registerFormElement: PropTypes.func,
    validateField: PropTypes.func,
  };

  state = {
    value: this.props.value,
  };

  componentDidMount() {
    if (this.context.registerFormElement) this.context.registerFormElement(this.props.name, this);
  }

  get value() {
    const val = this._ref.value;
    return this.props.trim ? val.trim() : val;
  }

  set value(value) {
    this._ref.value = value;
  }

  reset() {
    this.value = null;
  }

  validate() {
    const { validateField } = this.context;

    if (validateField) {
      const localError = validateField(this.props.name, this.value);
      this.setState({
        localError,
      });

      return false;
    }

    return true;
  }

  clearLocalError() {
    this.setState({
      localError: false,
    });
  }

  @autobind
  onBlur() {
    const { onBlur } = this.props;
    this.validate();
    if (onBlur) onBlur(this.value, this);
  }

  @autobind
  onChange() {
    const { onChange } = this.props;
    if (onChange) onChange(this.value);
  }

  get hasChanged() {
    return this.value !== (this.props.value || '');
  }

  render() {
    const {
      autoComplete,
      name,
      label,
      mandatory,
      disabled,
      readOnly,
      placeholder,
      maxLength,
      hasError,
      onFocus,
      showRemaining,
    } = this.props;
    const errorMessage = this.state.localError || this.props.error;
    const error = hasError || errorMessage;

    return (
      <Input
        label={label}
        mandatory={mandatory}
        id={name}
        type="text"
        autoComplete={autoComplete}
        readOnly={readOnly}
        placeholder={placeholder}
        maxLength={maxLength}
        ref={(ref) => (this._ref = ref)}
        defaultValue={this.state.value}
        disabled={disabled}
        noValidate
        onChange={this.onChange}
        onBlur={this.onBlur}
        onFocus={onFocus}
        error={error}
        showRemaining={showRemaining}
      />
    );
  }
}

export default FormInput;
