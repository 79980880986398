var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import CheckIcon from '../../../assets/svg/redesign/check.svg';
import MinusIcon from '../../../assets/svg/redesign/minus.svg';
export var Checkbox = forwardRef(function (_a, ref) {
    var _b = _a.size, size = _b === void 0 ? 'md' : _b, title = _a.title, description = _a.description, className = _a.className, classNames = _a.classNames, error = _a.error, props = __rest(_a, ["size", "title", "description", "className", "classNames", "error"]);
    return (React.createElement("label", { className: twMerge('flex gap-2', classNames === null || classNames === void 0 ? void 0 : classNames.root) },
        React.createElement("span", { className: twMerge('inline-block relative shrink-0 mt-1', size === 'lg' ? 'h-7 w-7' : 'h-5 w-5') },
            React.createElement("input", __assign({}, props, { ref: ref, type: "checkbox", className: twMerge('peer appearance-none outline outline-1 outline-ocean-blue-100 absolute inset-0 rounded-sm checked:bg-ocean-blue-100', error ? 'outline-red-500 outline-2' : '', className) })),
            React.createElement("span", { className: "hidden absolute inset-0 pointer-events-none select-none peer-checked:inline-block text-high-emphasis" },
                React.createElement(CheckIcon, null)),
            React.createElement("span", { className: "hidden absolute inset-0 pointer-events-none select-none peer-indeterminate:inline-block text-high-emphasis" },
                React.createElement(MinusIcon, null))),
        React.createElement("div", { className: twMerge('flex flex-col', classNames === null || classNames === void 0 ? void 0 : classNames.labelContent) },
            title && (React.createElement("p", { className: twMerge('text-md font-semibold', error ? 'text-red-500' : ''), "data-tailwind": true }, title)),
            description && (React.createElement("p", { className: twMerge('text-sm', error ? 'text-red-500' : ''), "data-tailwind": true }, description)),
            error && (React.createElement("p", { className: twMerge('text-red-500 text-sm'), "data-tailwind": true }, error)))));
});
