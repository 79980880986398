var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { twMerge } from 'tailwind-merge';
export var Text = function (_a) {
    var as = _a.as, _b = _a.type, type = _b === void 0 ? 'md' : _b, className = _a.className, rest = __rest(_a, ["as", "type", "className"]);
    // "Altlast": use the as prop if it's provided, otherwise use the type prop or default to 'p'
    var Component = as || (/^(h[0-9]{1})$/.test(type) ? type : 'p');
    return (React.createElement(Component, __assign({}, rest, { className: twMerge(type === 'xs' && 'text-xs leading-6', type === 'sm' && 'text-sm leading-6', type === 'md' && 'text-md leading-[28.8px]', type === 'h1' && 'text-h2 md:text-h1', type === 'h2' && 'text-h3 md:text-h2', type === 'h3' && 'text-h3 md:text-h3', className), "data-tailwind": true })));
};
