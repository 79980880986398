// @flow

import storage from '../../utils/storage';
import config from '../../config';
import { isAndroid, isIOS } from '../../utils/OSDetection';
import { kebabCase } from '../../utils/kebabCase';
import { BLACKLISTED_PATHS } from './blacklist';
import { authStore } from '../../stores/AuthenticationStore';
import { PAGE_NAME_REPLACEMENT_MAP } from './replacements';
import { generateLegacyPageType } from './legacy';
import { tealiumString } from '../../utils/tracking';
import type { TrackingData } from '../../types/tracking';

const PAGE_NAME_SEPARATOR = '|';

export function trackPageView(
  path: string,
  titles: string | Array<string>,
  browserLang: string,
  category: ?string = null
): ?TrackingData {
  if (isBlacklistedPath(path)) return null;

  const storedTrackingParams = getStoredTrackingParams();
  if (path === '/' && !storedTrackingParams) return null;

  const title = formatTitle(titles);
  const trackingData = buildTrackingData(path, title, category);

  return sendTrackingData(trackingData, browserLang, storedTrackingParams);
}

export function isBlacklistedPath(path: string): boolean {
  return BLACKLISTED_PATHS.some((pattern) => new RegExp(pattern).test(path));
}

function getStoredTrackingParams(): ?{
  trip_code: string,
  trip_date_end: string,
  trip_date_range: string,
  trip_date_start: string,
  trip_name: string,
  trip_ship_name: string,
  user_id: string,
} {
  const stored = storage.get('tracking');
  if (!stored) return null;
  return {
    trip_code: stored.tripCode || '',
    trip_date_end: stored.ddFull || '',
    trip_date_range: stored.period || '',
    trip_date_start: stored.edFull || '',
    trip_name: stored.tripName || '',
    trip_ship_name: stored.ship || '',
    user_id: stored.userId || '',
  };
}

function formatTitle(titles: string | Array<string>): string {
  const uniqueTitles = Array.isArray(titles) ? [...new Set(titles)] : [titles];
  return uniqueTitles
    .map(kebabCase)
    .join(PAGE_NAME_SEPARATOR)
    .replace(
      new RegExp(Object.keys(PAGE_NAME_REPLACEMENT_MAP).join('|'), 'g'),
      (key) => PAGE_NAME_REPLACEMENT_MAP[key] || key
    );
}

function buildTrackingData(path: string, title: string, category: ?string): TrackingData {
  const url = new URL(window.location.href);
  const hostname = url.hostname.replace(/\./g, '_');
  const query = url.search ? url.search.slice(1) : null;
  const isNativeApp = url.searchParams.get('app') === '1';

  return {
    page_name: tealiumString(`${hostname}${PAGE_NAME_SEPARATOR}${title}`),
    page_url_parameter: query,
    page_category: category || 'content',
    page_login_status: !!authStore.user?.apiToken,
    page_login_variant: authStore.user?.backLink ? 'konto' : 'meinereisen',
    page_type: generateLegacyPageType(path, title),
    page_platform: 'meinereisen',
    page_country: 'de',
    page_language: 'de',
    page_test_variant: 'original',
    page_native_app: isNativeApp,
    page_native_app_os: isIOS ? 'ios' : isAndroid ? 'android' : '',
    page_b2b: false,
  };
}

function sendTrackingData(
  data: TrackingData,
  browserLang: string,
  params: ?{
    trip_code: string,
    trip_date_end: string,
    trip_date_range: string,
    trip_date_start: string,
    trip_name: string,
    trip_ship_name: string,
    user_id: string,
  }
): TrackingData {
  let finalData = { ...data };

  if (data.page_type === 'home_logged_in' && params) {
    finalData = { ...params, ...finalData };
  }

  if (data.page_type !== 'home_logged_out') {
    finalData = { iadvize_site_id: config.iadvizeId, user_language: browserLang, ...finalData };
  }

  console.log('tracking view event', finalData);

  if (window.utag) {
    window.utag.view(finalData);
  }

  return finalData;
}
