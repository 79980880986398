// @flow
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { autobind } from 'core-decorators';

import InfoList from '../../InfoList';
import LinkAsButton from '../../LinkAsButton';
import LoadingIndicator from '../../LoadingIndicator';
import track from '../../../tracking';
import type BoardAccountStore from '../../../stores/BoardAccountStore';
import type MasterStore from '../../../stores/MasterStore';
import type TilesStore from '../../../stores/TilesStore';

import BookingTable from '../../BookingDetails/booking-table/BookingTable';

const numberOrDash = (item) => (`${item}` === '0' ? '-' : item);

type Props = {
  boardAccountStore: BoardAccountStore,
  masterStore: MasterStore,
  tilesStore: TilesStore,
};

@inject('boardAccountStore', 'masterStore', 'tilesStore')
@observer
export default class TravelAward extends Component<Props> {
  static breadcrumb = 'Reisepreis';

  componentDidMount() {
    // execute fetch but don't mind result
    const ignored = this.props.boardAccountStore.fetch();
    this.props.tilesStore.fetchAll();
    this.trackPageView();
  }

  trackPageView = () => {
    track.pageView(window.location.pathname, ['buchung-reiseunterlagen', 'reisepreis'], 'content');
  }

  @autobind
  handlePrint() {
    window.print();
  }

  render() {
    const { boardAccountStore, masterStore, tilesStore } = this.props;

    if (boardAccountStore.isLoading || tilesStore.isLoading) {
      return <LoadingIndicator />;
    }

    const { boardAccount } = boardAccountStore;
    const { masterData, itinerary } = masterStore;
    const { tiles } = tilesStore;
    if (!boardAccount) return null;

    return (
      <div className="page page-booking-details">
        <section className="l-row">
          <div className="l-col double">
            <h1>Ihre Buchung</h1>
            <InfoList
              infos={[
                {
                  key: 'Schiff:',
                  value: <em>{masterData.ship}</em>,
                },
                {
                  key: 'Kabinenart:',
                  value: boardAccount.cabinTypes.join(', '),
                },
                {
                  key: 'Kabinennummer:',
                  value: boardAccount.cabinNumbers.map(numberOrDash).join(', '),
                },
                {
                  key: 'Abfahrtshafen:',
                  value: itinerary.startHarbour,
                },
                {
                  key: 'Ankunftshafen:',
                  value: itinerary.endHarbour,
                },
              ]}
            />
            {tiles.einreisebestimmungen.visible ? (
              <LinkAsButton disabled={!tiles.einreisebestimmungen.enabled} link="/buchungsdetails/einreisebestimmungen">
                Einreisebestimmungen
              </LinkAsButton>
            ) : null}
          </div>
        </section>
        <hr className="l-mod" />
        <section>
          <h1>Reisepreis</h1>
          <BookingTable
            showParticipants
            items={boardAccount.travelExpenses.list}
            totalText={boardAccount.travelExpenses.totalText}
            showAmountOverride={true}
          />
        </section>
      </div>
    );
  }
}
