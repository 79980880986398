// @flow
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { dimensions } from '../../AccountHeader/styles';

import RegulationsSelect from './Select';
import LinkAsButton from '../../LinkAsButton';
import isInAppView from '../../../utils/isInAppView';

const SelectWrapper = styled.div`
  padding-bottom: 29px;
  @media (max-width: ${dimensions.mobileM}px) {
    padding-bottom: 10px;
  }
`;

const TapBar = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 604px;
  min-width: 604px;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  scrollbar-visibility: hidden;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  ${(p) =>
    p.count > 2 &&
    css`
      @media (min-width: ${dimensions.mobileM}px) {
        width: ${dimensions.mobileSM - 40}px;
      }
      @media (min-width: ${dimensions.mobileL}px) {
        width: ${dimensions.mobileM}px;
      }
      @media (min-width: 950px) {
        width: ${dimensions.mobileL}px;
      }
    `};
`;

const TapBarPart = styled.div`
  padding-bottom: 16px;
  flex: 1 1 auto;
  border-bottom: 2px solid;
  border-bottom-color: #5f99b1;
  font-size: 18px;
  font-weight: normal;
  color: #5f99b1;
  text-align: center;
  cursor: pointer;
  min-width: 200px;
  scroll-snap-align: center;

  @media (max-width: ${dimensions.mobileM}px) {
    display: none;
  }

  ${(p) =>
    p.active &&
    css`
      font-weight: bold;
      color: #253e6a;
      border-bottom-color: #263e6a;
    `};
`;

const BodyHeading = styled.div`
  font-size: 24px;
`;

const BodyPart = styled.div`
  display: none;
  font-size: 18px;

  ${(p) =>
    p.active &&
    css`
      display: block;
    `};
`;

const TapSelect = styled.div`
  display: none;
  @media (max-width: ${dimensions.mobileM}px) {
    display: block;
    flex: 1 1 auto;
    margin-top: -22px;
  }
`;

const PdfWrapper = styled.div`
  max-width: 604px;
  padding-bottom: 30px;

  @media (max-width: ${dimensions.mobileM}px) {
    h2 {
      font-size: 18px;
    }
  }

  a {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin: 0 0 10px 0;
    min-height: 48px;
    font-size: 18px;

    @media (max-width: ${dimensions.mobileM}px) {
      font-size: 15px;
      padding-left: 15px;
    }

    &:hover {
      &.icon.pdf::before {
        color: white;
      }
    }

    &.icon.pdf::before {
      font-size: 24px;
      padding-right: 24px;
      @media (max-width: ${dimensions.mobileM}px) {
        padding-right: 10px;
      }
    }
  }
`;

type Props = {
  regulations: {
    headline: string,
    texts: [
      {
        headline?: string,
        body: string,
      }
    ],
    pdfs: PDFData[],
    smallSelect: string[],
  },
};

function extractContent(html) {
  try {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const heading = doc.querySelector('h2').textContent;
    const body = Array.from(doc.querySelectorAll('p'))
      .map((p) => p.outerHTML)
      .filter((html) => !html.includes(heading))
      .join('');

    return {
      heading,
      body,
    };
  } catch (error) {
    console.warn(
      'Could not extract heading and body from api response: ',
      error
    );
    return {
      heading: undefined,
      body: html,
    };
  }
}
export default function ContentRegulations(props: Props) {
  const [view, setView] = useState(0);

  const { regulations } = props;

  const setOnClick = (i: number) => {
    setView(i);
  };

  return (
    <>
      {regulations.count > 1 ? (
        <SelectWrapper>
          <TapBar count={regulations.count}>
            {regulations.texts.map((text, i) => (
              <TapBarPart
                key={i}
                active={view === i || false}
                onClick={(e) => {
                  if (view !== i) {
                    setOnClick(i);
                    if (regulations.count > 3) {
                      e.currentTarget.parentElement.scroll((i - 1) * 100, 0);
                    }
                  }
                }}
              >
                {text.headline}
              </TapBarPart>
            ))}
          </TapBar>

          <TapSelect>
            <RegulationsSelect
              value={String(view)}
              name="selectPart"
              options={regulations.smallSelect}
              onChange={(i) => {
                i = parseInt(i, 10);
                if (view !== i) {
                  setOnClick(i);
                }
              }}
            />
          </TapSelect>
        </SelectWrapper>
      ) : null}

      <div>
        {regulations.texts.map((text, i) => {
          // workaround to extract the heading from the body content such that the heading can be styled
          // this should be adjusted in the api response
          const { heading, body } = extractContent(text.body);

          return (
            <>
              <BodyHeading>{heading}</BodyHeading>
              <BodyPart
                key={i}
                active={view === i || false}
                dangerouslySetInnerHTML={{ __html: body }}
              />
            </>
          );
        })}
      </div>

      {!!regulations.pdfLinks && !!regulations.pdfLinks.length && (
        <PdfWrapper>
          <h2>Als PDF herunterladen:</h2>
          {regulations.pdfLinks.map((pdf) => (
            <LinkAsButton
              key={pdf.url}
              asAnchor={isInAppView}
              newPage={!isInAppView}
              link={pdf.url}
              className="icon pdf button"
            >
              {pdf.displayName}
            </LinkAsButton>
          ))}
          <div className="clean" />
        </PdfWrapper>
      )}
    </>
  );
}
