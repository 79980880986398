var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
export var IconSecurityCheck = function (_a) {
    var props = __rest(_a, []);
    return (React.createElement("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fill: "#265734", fillRule: "evenodd", d: "M8.47 10.47a.75.75 0 0 1 1.06 0L12 12.94l7.47-7.47a.75.75 0 1 1 1.06 1.06l-8 8a.75.75 0 0 1-1.06 0l-3-3a.75.75 0 0 1 0-1.06", clipRule: "evenodd" }),
        React.createElement("path", { fill: "#265734", fillRule: "evenodd", d: "M12 4.75A7.25 7.25 0 1 0 19.25 12a.75.75 0 0 1 1.5 0 8.75 8.75 0 1 1-5.798-8.24.75.75 0 0 1-.506 1.413A7.2 7.2 0 0 0 12 4.75", clipRule: "evenodd" })));
};
