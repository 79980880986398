var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef, } from 'react';
import { tv } from 'tailwind-variants';
var buttonVariants = tv({
    base: [
        'rounded-lg flex gap-2 items-center justify-center font-semibold',
        'transition-all duration-300  text-md',
        'disabled:bg-marine-blue-20 disabled:text-white hover:bg-marine-blue-60 hover:text-white',
    ],
    variants: {
        size: {
            md: 'px-8 py-3',
            lg: 'px-10 py-4',
        },
        variant: {
            primary: 'bg-ocean-blue-100 text-high-emphasis',
            secondary: 'outline outline-1 outline-ocean-blue-100 text-high-emphasis',
            dark: 'text-white bg-blue-dark',
        },
    },
    defaultVariants: {
        size: 'md',
        variant: 'primary',
    },
});
export var Button = forwardRef(function (_a, ref) {
    var as = _a.as, _b = _a.variant, variant = _b === void 0 ? 'primary' : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c, children = _a.children, className = _a.className, props = __rest(_a, ["as", "variant", "disabled", "children", "className"]);
    var Component = as !== null && as !== void 0 ? as : 'button';
    var buttonClass = buttonVariants({
        className: className,
        variant: variant,
    });
    return (React.createElement(Component, __assign({}, props, { ref: ref, disabled: disabled, className: buttonClass, "data-tailwind": true }), children));
});
