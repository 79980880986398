import * as Sentry from '@sentry/browser';
import { getEnvironment } from '../utils/environment';

// List of subpaths that are allowed to be reported to Sentry
// Items in this list are wildcard matched. So "/manifest/asd" will also match for "manifest"
const ALLOWED_SUBPATHS = ['manifest', 'payment', 'checkin'];

// regex that matches any url with a subpath that starts with one of the ALLOWED_SUBPATHS
const ALLOWED_REGEX = new RegExp(`^https?:\/\/[^\/]+\/(${ALLOWED_SUBPATHS.join('|')})($|\/.*$)`);

Sentry.init({
  dsn: 'https://0e102c5723bac49ec70a1402862e4b2d@o4507418626621440.ingest.de.sentry.io/4508290666004560',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],

  environment: getEnvironment(),

  // Only report errors from URLs that match the regex defined above
  beforeSend: (event) => (ALLOWED_REGEX.test(event.request.url) ? event : null),

  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions

  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    // if you want test sentry locally you need to uncomment the line below
    // /^http:\/\/local\.dev\.cellular\.de/,
    /^https:\/\/tuic-mrl\.dev\.cellular\.de/,
    /^https:\/\/meinereise\.test\.meinschiff\.com/,
    /^https:\/\/meinereise\.meinschiff\.com/,
  ],

  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
