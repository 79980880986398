var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import NavArrowDownIcon from '../../../assets/svg/redesign/nav-arrow-down.svg';
export var Select = forwardRef(function (_a, ref) {
    var label = _a.label, error = _a.error, options = _a.options, mandatory = _a.mandatory, placeholder = _a.placeholder, props = __rest(_a, ["label", "error", "options", "mandatory", "placeholder"]);
    var sortedOptions = useMemo(function () { return options.sort(function (a, b) { return (a.priority ? -1 : b.priority ? 1 : 0); }); }, [options]);
    return (React.createElement("label", { className: "space-y-1 block" },
        label && (React.createElement("p", { className: "font-semibold", "data-tailwind": true },
            label,
            !!mandatory && '*')),
        React.createElement("div", { className: twMerge('relative bg-white rounded-md outline outline-1 outline-gray-300 inline-block mt-1 w-full', 'disabled:bg-marine-10 disabled:text-high-emphasis disabled:border-transparent disabled:outline-none disabled:cursor-not-allowed', !!error && 'outline-2 outline-red-500') },
            React.createElement("select", __assign({}, props, { ref: ref, className: "appearance-none z-20 p-3 w-full bg-transparent relative", "data-tailwind": true }),
                placeholder && React.createElement("option", { value: "" }, placeholder),
                sortedOptions.map(function (option) { return (React.createElement("option", { key: option.value, value: option.value, disabled: option.disabled }, option.label)); })),
            React.createElement("span", { className: "absolute z-10 right-2 top-0 bottom-0 flex items-center justify-center size-4 m-auto" },
                React.createElement(NavArrowDownIcon, null))),
        error && React.createElement("p", { className: "text-red-500 text-xs" }, error)));
});
