import React from 'react';
import { LogoTUI } from '../LogoTUI';
import tracking from '../../../tracking';
export var LayoutFooter = function () {
    return (React.createElement("footer", { className: "bg-ocean-blue-100 border-t border-solid border-stroke-secondary-40" },
        React.createElement("div", { className: "container my-8" },
            React.createElement("div", { className: "flex flex-wrap md:flex-nowrap gap-4 md:justify-between mx-3" },
                React.createElement("div", null,
                    React.createElement("nav", { className: "flex gap-2" },
                        React.createElement("a", { href: "http://meinschiff.com/", onClick: function () { return tracking.click('footer.link.Mein Schiff.com'); }, target: "_blank" },
                            React.createElement("i", { className: "whitespace-nowrap" }, "MeinSchiff"),
                            ".com"),
                        React.createElement("a", { className: "border-l border-solid pl-2", href: "http://meinschiff.com/impressum/", onClick: function () { return tracking.click('footer.link.Impressum'); }, target: "_blank" }, "Impressum"),
                        React.createElement("a", { className: "border-l border-solid pl-2", href: "http://meinschiff.com/agb/", onClick: function () { return tracking.click('footer.link.AGB'); }, target: "_blank" }, "AGB"))),
                React.createElement("div", { className: "grow text-right min-w-28 max-w-52 flex justify-end" },
                    React.createElement(LogoTUI, { height: "auto", width: "100%" }))))));
};
