// @flow
import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { observer } from 'mobx-react';

import Button from '../../../components/Button';
import FormInput from '../../../components/form/Input';
import FormSelect from '../../../components/form/Select';
import LoadingIndicator from '../../../components/LoadingIndicator';

const PAPAGENA_IFRAME_ORIGIN = window.location.origin;
const PAPAGENA_CREDITCARD_METHOD = 'post';
const PAPAGENA_CREDITCARD_URL = 'https://security.papagena-payment.de/register/credit-card-psd2.php';
// Use this instead, if you just need a quick and dirty test:
// const PAPAGENA_CREDITCARD_METHOD = 'get';
// const PAPAGENA_CREDITCARD_URL = '/papagena-success-response.html';
const PAPAGENA_POPUP_NAME = 'papagena-popup';
// HINT: Config-Doku: https://confluence.cellular.de/x/fqUo
const POPUP_CONFIG = {
  returnURL: `${PAPAGENA_IFRAME_ORIGIN}/papagena-response.html`,
  // form styles and language
  Template: 'ct_responsive',
  BGColor: 'transparent',
  FColor: '253e6a',
  FFace: 'Verdana',
  FSize: '2',
  Language: 'de',
  Center: '1',
  tWidth: '100%',
  tHeight: '100%',
};

import type PaymentStoreRequest from '../../../models/PaymentStoreRequest';
import { PAYMENT_OPTION_CASH } from '../../../config/constants';

type Props = {
  storeRequest: PaymentStoreRequest,
  isInAppView: boolean,
  disabled: boolean,
};

type State = {
  showPaymentFrame: boolean,
  cardChangeRequested: boolean,
  isLoadingFrame: boolean,
};

@observer
export default class ManifestPaymentCreditCardOption extends Component<Props, State> {
  state = {
    showPaymentFrame: !this.props.storeRequest.hasEnteredCreditCard,
    cardChangeRequested: false,
    isLoadingFrame: !this.props.storeRequest.hasEnteredCreditCard,
  };

  data = {};
  _form: window.HTMLFormElement;
  paymentPopup: window.Window;
  paymentFrame: window.HTMLIFrameElement;
  checkPopupInterval: ?number;
  checkFrameInterval: ?number;

  componentDidMount() {
    if (this._form && (this.state.showPaymentFrame || this.state.cardChangeRequested)) {
      this.props.storeRequest.createNewTransactionId();
      this._form.submit();
      this.checkFrameInterval = window.setInterval(this.checkFrame, 200);
    }
  }

  componentDidUpdate() {
    if (this._form && this.state.showPaymentFrame && this.state.cardChangeRequested) {
      this._form.submit();
      this.checkFrameInterval = window.setInterval(this.checkFrame, 200);
    }
  }

  componentWillUnmount() {
    this.checkFrameInterval && window.clearInterval(this.checkFrameInterval);
  }

  @autobind
  checkFrame() {
    this.setState({ cardChangeRequested: false });
    try {
      if (!this.paymentFrame) return;

      const { storeRequest } = this.props;
      const data = this.paymentFrame.contentWindow.tuiPapagenaData;

      if (data) {
        storeRequest.receivePapagenaResponse(data);
        this.setState({ showPaymentFrame: false });
        this.checkFrameInterval && window.clearInterval(this.checkFrameInterval);
      }
    } catch (e) {
      // nothing to do here
    }
  }

  @autobind
  handleOpenCreditCardWindow() {
    this.openPopup();
    this._form.submit();
  }

  @autobind
  openPopup() {
    this.paymentPopup = window.open('', PAPAGENA_POPUP_NAME, 'width=500,height=680,resizable,scrollbars');
    this.checkPopupInterval = window.setInterval(this.checkPopup, 100);
    // We just used up the transaction Id, if the user maybe accidentally closes the popup, we need
    // to have another one ready to use.
    this.props.storeRequest.createNewTransactionId();
  }

  @autobind
  checkPopup() {
    try {
      if (!this.paymentPopup) return;
      const { storeRequest } = this.props;
      if (this.paymentPopup.tuiPapagenaData && this.paymentPopup.location.origin === window.location.origin) {
        storeRequest.receivePapagenaResponse(this.paymentPopup.tuiPapagenaData);
        this.checkPopupInterval && window.clearInterval(this.checkPopupInterval);
        this.paymentPopup.close();
        this.paymentPopup = null;
      }
    } catch (e) {
      // nothing to do here
    }
  }

  renderCreditCardData() {
    const { creditCardInfo, hasCreditCardInfo, paymentData } = this.props.storeRequest;
    const type = paymentData ? paymentData.type : null;

    return hasCreditCardInfo && creditCardInfo && type !== 'SEPA' ? (
      <div>
        <div className="l-row">
          <div className="l-col">
            <FormInput
              disabled
              label="Karteninhaber"
              name="customer"
              value={creditCardInfo.displayCustomer}
              key={creditCardInfo.displayCustomer}
            />
          </div>
          <div className="l-col">
            <FormInput
              disabled
              label="Kartennummer"
              name="cardnr"
              value={creditCardInfo.displayCardNumber}
              key={creditCardInfo.displayCardNumber}
            />
          </div>
        </div>
        <div className="l-row">
          <div className="l-col">
            <label className="form-date__label">Gültig bis (Monat / Jahr)</label>
            <div className="l-spread">
              <div className="l-spread_left">
                <FormSelect
                  disabled
                  name="validUntilMonth"
                  placeholder={creditCardInfo.displayValidUntilMonth}
                  options={[]}
                />
              </div>
              <div className="l-spread_right">
                <FormSelect
                  disabled
                  name="validUntilYear"
                  placeholder={creditCardInfo.displayValidUntilYear}
                  options={[]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }

  @autobind
  handleChangeCreditCard() {
    this.setState({});
    this.setState({
      showPaymentFrame: true,
      cardChangeRequested: true,
      isLoadingFrame: true,
    });
    this.props.storeRequest.createNewTransactionId();
  }

  renderSuccess() {
    const { disabled } = this.props;
    return (
      <div className="flex flex-col">
        {this.renderCreditCardData()}
        <div className="w-full md:w-auto">
          <Button disabled={disabled} fullWidth onClick={this.handleChangeCreditCard} className="l-mod-sub">
            Kreditkarte ändern
          </Button>
        </div>
      </div>
    );
  }

  @autobind
  onLoadFrame() {
    this.setState({ isLoadingFrame: false });
  }

  renderForm() {
    const { disabled, storeRequest } = this.props;

    const showPaymentFrame = this.state.cardChangeRequested || this.state.showPaymentFrame;

    const showChangeButton = storeRequest.hasEnteredCreditCard && !showPaymentFrame;

    return (
      <div className="w-full mx-auto">
        {this.renderCreditCardData()}
        {this.state.isLoadingFrame && <LoadingIndicator />}
        {showPaymentFrame && (
          <iframe
            ref={(ref) => {
              this.paymentFrame = ref;
            }}
            src=""
            name={PAPAGENA_POPUP_NAME}
            frameBorder="0"
            width="100%"
            height="680"
            scrolling="no"
            onLoad={this.onLoadFrame}
            className={this.state.isLoadingFrame ? 'is-hidden' : ''}
          />
        )}
        {showChangeButton && (
          <div className="w-full md:w-auto self-start">
            <Button disabled={disabled} fullWidth onClick={this.handleChangeCreditCard}>
              Kreditkarte ändern
            </Button>
          </div>
        )}
      </div>
    );
  }

  render() {
    const { storeRequest } = this.props;
    const popupRequestData = {
      ...storeRequest.creditCardPopupData,
      ...POPUP_CONFIG,
    };

    return (
      <div>
        {storeRequest.error && (
          <div className="l-right">
            <button
              className="retry"
              onClick={() => {
                // TUICROLLOUTS-674
                storeRequest.cleanError();
                const option = storeRequest.selectedOptionOrDefault;
                storeRequest.changeOption(PAYMENT_OPTION_CASH);

                window.setTimeout(() => {
                  storeRequest.changeOption(option);
                }, 128);
              }}
            >
              Erneut eingeben
            </button>
          </div>
        )}
        <form
          ref={(ref) => {
            this._form = ref;
          }}
          method={PAPAGENA_CREDITCARD_METHOD}
          action={PAPAGENA_CREDITCARD_URL}
          target={PAPAGENA_POPUP_NAME}
        >
          {Object.keys(popupRequestData).map((key) => (
            <input key={key} type="hidden" name={key} value={popupRequestData[key] || ''} />
          ))}
        </form>
        {this.renderForm()}
      </div>
    );
  }
}
