// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import Headline from '../../Headline';
import SpaCategoryTiles from '../../SpaCategoryTiles';
import track from '../../../tracking';

import type SpaStore from '../../../stores/SpaStore';

type Props = {
  spaStore: SpaStore,
  location: { pathname: string },
  breadcrumbRouterStore: {
    getWithoutFirst: Array<string>,
  },
};

export default function spaCategories(type: string, title: string, headlineIcon: string) {
  @inject('spaStore', 'breadcrumbRouterStore')
  @observer
  class SpaCategories extends Component<Props> {
    static breadcrumb = title;

    componentDidMount() {
      this.props.spaStore.fetchCategories();

      // hacky way to wait for next tick to ensure breadcrumb store is updated
      setTimeout(() => {
        this.trackPageView();
      }, 0);
    }

    componentDidUpdate(prevProps) {
      if (prevProps.location.pathname !== this.props.location.pathname) {
        this.trackPageView();
      }
    }

    trackPageView = () => {
      const { breadcrumbRouterStore, location } = this.props;
      const titles = breadcrumbRouterStore.getWithoutFirst;

      if (titles.length) {
        track.pageView(location.pathname, titles, titles.length > 1 ? 'sub-category' : 'category');
      }
    };

    render() {
      const { spaStore, location } = this.props;
      const categories = spaStore.categoriesByType[type] || [];

      return (
        <div className="page">
          <SpaCategoryTiles categories={categories} linkBase={this.props.location.pathname} />
        </div>
      );
    }
  }

  return SpaCategories;
}
