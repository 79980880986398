// TODO: add flow types

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import Headline from './Headline';
import HeroImage from './HeroImage';
import LoadingIndicator from './LoadingIndicator';

@inject('staticContentStore')
@observer
export default class ContentOfPage extends Component {
  static propTypes = {
    children: PropTypes.node,
    // Add this if you need an additional subtitle line
    subtitle: PropTypes.string,
    headlineIcon: PropTypes.string,
    contentFor: PropTypes.string.isRequired,
    staticContentStore: PropTypes.object.isRequired,
    hideHeadline: PropTypes.boolean,
  };

  state = {
    content: null,
    loading: true,
  };

  componentDidMount() {
    this.props.staticContentStore
      .fetch(this.props.contentFor)
      .then((content) => {
        this.setState({
          content,
          loading: false,
        });
      });
  }

  render() {
    if (this.state.loading) {
      return <LoadingIndicator />;
    }

    const { content } = this.state;
    const { children, subtitle, headlineIcon } = this.props;

    return (
      <div>
        {!this.props.hideHeadline && (
          <Headline
            title={content.headline}
            subtitle={subtitle}
            icon={headlineIcon}
          />
        )}
        <HeroImage src={content.imageSrc} alt="" className="l-mod-sub" />
        <div className="l-row">
          <div className="l-col double">
            <p dangerouslySetInnerHTML={{ __html: content.text }} />
          </div>
        </div>
        {children}
      </div>
    );
  }
}
