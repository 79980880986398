var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef, useImperativeHandle, useRef, useState, } from 'react';
import { twMerge } from 'tailwind-merge';
export var Input = forwardRef(function (_a, outerRef) {
    var _b, _c;
    var label = _a.label, error = _a.error, mandatory = _a.mandatory, showRemaining = _a.showRemaining, onChange = _a.onChange, grow = _a.grow, props = __rest(_a, ["label", "error", "mandatory", "showRemaining", "onChange", "grow"]);
    var ref = useRef(null);
    useImperativeHandle(outerRef, function () { return ref.current; }, []);
    var _d = useState(props.maxLength && showRemaining
        ? props.maxLength - ((_c = (_b = ref.current) === null || _b === void 0 ? void 0 : _b.value) !== null && _c !== void 0 ? _c : '').length
        : undefined), remainingCount = _d[0], setRemainingCount = _d[1];
    var handleChange = function (event) {
        var _a, _b;
        onChange === null || onChange === void 0 ? void 0 : onChange(event.target.value);
        if (!props.maxLength || !showRemaining)
            return;
        setRemainingCount(props.maxLength - ((_b = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '').length);
    };
    return (React.createElement("label", { className: twMerge('space-y-1 block', grow && 'grow') },
        label && (React.createElement("p", { className: 'font-semibold', "data-tailwind": true },
            label,
            mandatory ? '*' : '')),
        React.createElement("input", __assign({}, props, { ref: ref, className: twMerge('appearance-none rounded-md outline outline-1 outline-gray-300 inline-block p-3 w-full bg-white', 'disabled:bg-marine-10 disabled:text-high-emphasis disabled:border-transparent disabled:outline-none disabled:cursor-not-allowed', error && 'outline-2 outline-red-500'), onChange: handleChange, "data-tailwind": true })),
        React.createElement("div", { className: 'flex' },
            error && React.createElement("p", { className: 'text-red-500 text-xs' }, error),
            remainingCount && (React.createElement("p", { className: 'text-xs ml-auto' }, remainingCount)))));
});
