// @flow
import React from 'react';

import ContentRegulations from './Content';
import { Text } from '../../redesign/Text';

type Props = React.ComponentPropsWithoutRef<typeof ContentRegulations>;

export default function ViewRegulations(props: Props) {
  const { regulations } = props;

  return (
    <div className="page page-manifest-entry-reqs">
      <Text type="h1" className="mb-4">
        {regulations.headline}
      </Text>
      <ContentRegulations regulations={regulations} />
    </div>
  );
}
