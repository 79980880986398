// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Headline = ({ className, title, icon, subtitle, subsubtitle }) => (
  <div
    className={classNames({
      headline: true,
      [className]: !!className,
    })}
  >
    {icon ? <i className={`icon ${icon}`} /> : null}
    {typeof title === 'string' ? (
      <h1 className="headline__h1" dangerouslySetInnerHTML={{ __html: title }} />
    ) : (
      <h1 className="headline__h1">{title}</h1>
    )}
    {subtitle ? (
      typeof subtitle === 'string' ? (
        <h2 className="headline__h2" dangerouslySetInnerHTML={{ __html: subtitle }} />
      ) : (
        <h2 className="headline__h2">{subtitle}</h2>
      )
    ) : null}
    {subsubtitle ? (
      typeof subsubtitle === 'string' ? (
        <h3 className="headline__h3" dangerouslySetInnerHTML={{ __html: subsubtitle }} />
      ) : (
        <h3 className="headline__h3">{subsubtitle}</h3>
      )
    ) : null}
  </div>
);

Headline.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node.isRequired,
  // The SVG icon component if we hwant to show an image
  icon: PropTypes.node,
  subtitle: PropTypes.node,
  subsubtitle: PropTypes.node,
};

export default Headline;
