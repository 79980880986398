var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useCallback, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { IconChevronDown } from './icons/IconChevronDown';
export var Accordion = function (_a) {
    var items = _a.items, _b = _a.initialOpen, initialOpen = _b === void 0 ? [] : _b, _c = _a.isSingleOpened, isSingleOpened = _c === void 0 ? false : _c;
    var _d = useState(initialOpen), openItems = _d[0], setOpenItems = _d[1];
    useEffect(function () {
        var itemsToOpen = initialOpen.filter(function (name) { return !openItems.includes(name); });
        if (itemsToOpen.length) {
            setOpenItems(__spreadArray(__spreadArray([], openItems, true), itemsToOpen, true));
        }
    }, [initialOpen]);
    var toggleItem = useCallback(function (itemName) {
        setOpenItems(function (current) {
            var isAlreadyOpen = current.includes(itemName);
            if (isSingleOpened) {
                return isAlreadyOpen ? [] : [itemName];
            }
            if (isAlreadyOpen) {
                return current.filter(function (name) { return name !== itemName; });
            }
            return __spreadArray(__spreadArray([], current, true), [itemName], false);
        });
    }, [setOpenItems, isSingleOpened]);
    return (React.createElement("div", null, Object.entries(items).map(function (_a) {
        var id = _a[0], item = _a[1];
        return (React.createElement("div", { key: id, className: twMerge('border-b border-dashed border-stroke-secondary-40 last:border-b-0') },
            React.createElement("input", { key: "controlled-".concat(id), checked: openItems.includes(id), "data-tailwind": true, tabIndex: -1, id: id, type: 'checkbox', onChange: function () { }, className: twMerge('peer appearance-none block h-0 w-0 border-0') }),
            React.createElement("button", { "data-tailwind": true, id: "".concat(id, "-label"), "aria-controls": "".concat(id, "-content"), "aria-expanded": openItems.includes(id), className: twMerge('cursor-pointer h-20 flex w-full items-center justify-between text-left focus:outline-offset-4 focus:outline-primary-600'), onClick: function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    toggleItem(id);
                } },
                React.createElement("span", { className: "font-semibold leading-7 text-lg text-ocean-blue-120" }, item.opener(openItems.includes(id))),
                React.createElement("span", { className: twMerge('ml-6 flex h-7 items-center transition-transform', openItems.includes(id) && 'rotate-180') },
                    React.createElement(IconChevronDown, null))),
            React.createElement("section", { id: "".concat(id, "-content"), "aria-labelledby": "".concat(id, "-label"), className: twMerge('basis-full transition-all ease-in-out duration-300 select-text grid grid-rows-[0fr]', 'peer-checked:grid-rows-[1fr]') },
                React.createElement("div", { className: twMerge('overflow-hidden', 'p-[1px]' // workaround to show borders of inputs touching the outside of the accordion content
                    ) }, item.content))));
    })));
};
