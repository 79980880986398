// @flow

import * as React from 'react';

/**
 * Adds width and height attributes to its images urls
 */
export const italicShip = (text: string) => text.replace(/(Mein Schiff)/g, '<i>$1</i>');

export const italicShipComponent = (text: string) => {
  const matches = text.match(/Mein Schiff (\d)+/);
  if (!matches) return text;
  const shipNumber = matches[1];
  return (
    <span>
      {text.split(/Mein Schiff \d+/).reduce((stack, text, index, array) => {
        stack.push(text);
        index < array.length - 1 && stack.push(<i key={index}>Mein Schiff {shipNumber}</i>);
        return stack;
      }, [])}
    </span>
  );
};

export const isAlphanumeric = (s: ?string): boolean => {
  if (!s) s = '';
  return /^\w+$/.test(s) && s.indexOf('_') <= -1;
};

export const infoLine = (fields: any[], separator: string = ', '): string => {
  return fields
    .filter((f) => !!f)
    .map((f) => `<span class="field">${f}</span>`)
    .join(separator);
};

export function validateIban(input) {
  // Remove whitespaces and convert to uppercase
  const iban = `${input}`.replace(/\s+/g, '').toUpperCase();

  // Check if the IBAN has the correct length for the country
  const ibanLengths = {
    AL: 28,
    AD: 24,
    AT: 20,
    AZ: 28,
    BH: 22,
    BE: 16,
    BA: 20,
    BR: 29,
    BG: 22,
    CR: 22,
    HR: 21,
    CY: 28,
    CZ: 24,
    DK: 18,
    DO: 28,
    EE: 20,
    FO: 18,
    FI: 18,
    FR: 27,
    GE: 22,
    DE: 22,
    GI: 23,
    GR: 27,
    GL: 18,
    GT: 28,
    HU: 28,
    IS: 26,
    IE: 22,
    IL: 23,
    IT: 27,
    JO: 30,
    KZ: 20,
    XK: 20,
    KW: 30,
    LV: 21,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    MT: 31,
    MR: 27,
    MU: 30,
    MC: 27,
    MD: 24,
    ME: 22,
    NL: 18,
    MK: 19,
    NO: 15,
    PK: 24,
    PS: 29,
    PL: 28,
    PT: 25,
    QA: 29,
    RO: 24,
    SM: 27,
    SA: 24,
    RS: 22,
    SK: 24,
    SI: 19,
    ES: 24,
    SE: 24,
    CH: 21,
    TN: 24,
    TR: 26,
    AE: 23,
    GB: 22,
    VG: 24,
  };

  const countryCode = iban.slice(0, 2);
  if (!ibanLengths[countryCode] || iban.length !== ibanLengths[countryCode]) {
    return false;
  }

  // Move the first four characters to the end
  const rearrangedIban = iban.slice(4) + iban.slice(0, 4);

  // Replace letters with numbers (A = 10, B = 11, ..., Z = 35)
  const numericIban = rearrangedIban
    .split('')
    .map((char) => {
      const code = char.charCodeAt(0);
      return code >= 65 && code <= 90 ? code - 55 : char;
    })
    .join('');

  // Calculate the modulo 97 and check if the remainder is 1
  const remainder = numericIban.match(/\d{1,7}/g).reduce((acc, part) => {
    return parseInt(acc + part, 10) % 97;
  }, '');

  return remainder === 1 ? iban : false;
}
