// @flow

import React, { Component, type Node } from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Alert } from './redesign/Alert';
import { Text } from './redesign/Text';

type Props = {
  children: Node,
  // If set to true, we will not add a box around that item
  plain?: boolean,
  // This text is always shown
  title?: Node,
  // This has to be a node, that we just put into content as teaserContent
  teaserContent?: Node,
  // Text of link to show more
  moreLink: string,
  // Text of link to show the less state again (if null, no less link is shown)
  lessLink?: string,
};

@observer
export default class ToggleInfoBox extends Component<Props> {
  @observable open = false;

  @action.bound
  toggle(event: SyntheticEvent<>) {
    event.preventDefault();
    this.open = !this.open;
  }

  render() {
    const { children, title, teaserContent, moreLink, lessLink, plain } = this.props;
    const clsPrefix = plain ? 'morebox' : 'infobox';

    return (
      <Alert mode="neutral">
        {title}
        {this.open ? (
          <div className="relative">{children}</div>
        ) : (
          <div className="relative">
            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-0 pointer-events-none"></div>
            <div className="relative text-transparent bg-clip-text bg-gradient-to-b from-black to-transparent overflow-hidden max-h-24">
              {teaserContent}
            </div>
          </div>
        )}
        <Text type="sm">
          {this.open ? (
            <a href="#" onClick={this.toggle}>
              {lessLink}
            </a>
          ) : (
            <a href="#" onClick={this.toggle}>
              {moreLink}
            </a>
          )}
        </Text>
      </Alert>
    );
  }
}
