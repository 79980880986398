import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { Radio } from '../redesign/Radio';

export default class FormRadio extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    label: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    grouped: PropTypes.bool,
    mandatory: PropTypes.bool,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    hasError: PropTypes.bool,
  };

  static contextTypes = {
    registerFormElement: PropTypes.func,
    validateField: PropTypes.func,
  };

  state = {};

  componentDidMount() {
    this.context.registerFormElement && this.context.registerFormElement(this.props.name, this, this.props.grouped);
  }

  get value() {
    return this._ref && this._ref.checked ? this.props.value || true : null;
  }

  validate() {
    const { validateField } = this.context;

    if (validateField) {
      const localError = validateField(this.props.name, this.value);
      this.setState({
        localError,
      });
    }
  }

  clearLocalError() {
    this.setState({
      localError: false,
    });
  }

  // changed onChange to Onclick within the radio cause it seems that on develop
  // onChange doesnt work properly
  @autobind
  handleChange() {
    if (this.props.onChange) this.props.onChange(this.value);
  }

  get hasChanged() {
    return this.value !== this.props.checked;
  }

  render() {
    const { name, label, className, mandatory, checked, disabled, hasError } = this.props;
    const { localError } = this.state;
    const error = hasError || localError;

    return (
      <Radio
        classNames={{ root: className }}
        name={name}
        description={
          <>
            {label}
            {mandatory ? '*' : ''}
          </>
        }
        mandatory={mandatory}
        disabled={disabled}
        checked={checked}
        onChange={this.handleChange}
        ref={(ref) => (this._ref = ref)}
        error={error}
      />
    );
  }
}
