// @flow
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import { routerShape } from 'react-router/lib/PropTypes';

import preload from '../../../components/pages/decorators/preload';
import List from '../../../components/lists/List';
import PaymentTile from '../../../components/tiles/PaymentTile';
import pinStorage from '../../../utils/pinStorage';
import storage from '../../../utils/storage';
import LoadingIndicator from '../../LoadingIndicator';
import ContentBox from '../../../components/ContentBox';
import { PinDialog } from '../../pin/PinDialog';
import InfoIcon from '../../../../assets/svg/inline/ic-info.inline.svg';
import track from '../../../tracking';

import type MasterStore from '../../../stores/MasterStore';
import type PartyMember from '../../../models/PartyMember';
import type DigitalHealthStore from '../../../stores/DigitalHealthStore';
import type TilesStore from '../../../stores/TilesStore';
import { Text } from '../../redesign/Text';

type Props = {
  masterStore: MasterStore,
  digitalHealthStore: DigitalHealthStore,
  tilesStore: TilesStore,
};

type State = {
  showPinModal: boolean,
  partyMember: ?PartyMember,
  url: ?string,
  error: ?Object,
};

@inject('digitalHealthStore', 'tilesStore')
@preload({ masterStore: 'MasterStore' })
@observer
export default class PagePaymentOverview extends Component<Props, State> {
  static breadcrumb = 'Zahlungsmittel';

  static contextTypes = {
    router: routerShape,
  };

  state = {
    showPinModal: false,
    partyMember: null,
    url: null,
    error: null,
  };

  componentDidMount() {
    this.checkPinState();
    const { digitalHealthStore } = this.props;
    digitalHealthStore.info();
    storage.remove('language');
    this.props.tilesStore.fetchAll();
    track.pageView(window.location.pathname, ['zahlungsmittel'], 'content');
  }

  checkPinState() {
    // TUICUNIT-911
    let error = pinStorage.getApiPinError();
    if (error) {
      const { masterStore } = this.props;
      const partyMember = masterStore.getTravelPartyMemberByMpi(error.mpi);

      if (partyMember) {
        this.setState({
          partyMember,
          showPinModal: true,
          error,
          url: pinStorage.getUrl(partyMember.mpi),
        });
      }

      pinStorage.removeApiPinError();
    }
  }

  @autobind
  onTileClick(partyMember: PartyMember, url: string) {
    // TUICUNIT-911
    const pin = partyMember ? pinStorage.getPin(partyMember.mpi) : null;

    if (pin || !partyMember.pinAvailable) {
      this.context.router.replace(url);
      return;
    }

    this.setState({
      showPinModal: true,
      partyMember,
      url,
    });
  }

  renderTravelPartyList() {
    const masterData = this.props.masterStore.masterData;
    const { tiles } = this.props.tilesStore;
    if (masterData.paymentAvailable || masterData.digitalHealthAvailable) {
      return (
        <section className="space-y-8">
          {masterData.travelParty.map((member) => (
            <div key={member.mpi}>
              <Text type="h2" className="mb-4">
                {member.displayName}
              </Text>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <PaymentTile
                  travelParty={masterData.travelParty}
                  partyMember={member}
                  linkBase="/payment"
                  configEnabled={tiles.zahlungsmittel.enabled}
                  configNotification={tiles.zahlungsmittel.notification}
                  state={member.paymentCompleted && !member.paymentPreliminary ? 'done' : 'open'}
                  onClick={this.onTileClick}
                />
              </div>
            </div>
          ))}
        </section>
      );
    }

    return (
      <div className="l-row">
        <div className="l-col double">
          <section>
            {tiles.schiffsManifest.visible ? (
              <List className="l-mod-sub">
                {masterData.travelParty.map((member, index) => {
                  let disabled = false;
                  let badge = member.manifestPreliminary
                    ? 'In Bearbeitung'
                    : member.manifestCompleted
                    ? null
                    : 'Daten sind unvollständig';

                  // TUICUNIT-2856 if tilesconfig enable ignore other state and badge
                  if (tiles.schiffsManifest.enabled === false) {
                    disabled = true;
                    badge = tiles.schiffsManifest.notification;
                  }
                  return (
                    <List.Item
                      key={member.mpi}
                      link=""
                      disabled={disabled}
                      badge={badge}
                      onClick={(e) => {
                        e.preventDefault();
                        this.onTileClick(member, `/manifest/manifest/${index}`);
                        return false;
                      }}
                    >
                      <List.Item.Header>{member.displayName}</List.Item.Header>
                      <List.Item.Text>
                        {member.manifestCompleted
                          ? 'Die Daten sind vollständig'
                          : 'Bitte vervollständigen Sie Ihr Schiffsmanifest'}
                      </List.Item.Text>
                    </List.Item>
                  );
                })}
              </List>
            ) : null}
          </section>
        </div>
      </div>
    );
  }

  // TUICUNIT-2919
  // TUICUNIT-3474: deprecated
  renderHealthHint() {
    const masterData = this.props.masterStore.masterData;
    //(TUICMRL-783) PartialSave ist only allow by more then 7 days
    const under7Days = !masterData.manifestAllowsPartialSave;
    const journeyOngoing = masterData.journeyOngoing;

    if (masterData.digitalHealthStateNotAvailable && under7Days && !journeyOngoing) {
      return (
        <ContentBox title="Technische Störung" titleIcon={<InfoIcon />}>
          <p>
            Wir bitten um Verständnis, dass der Gesundheitsfragebogen derzeit infolge einer technischen Herausforderung
            zeitweise nicht dargestellt werden kann. Wir bitten Sie in diesem Fall, es zu einem späteren Zeitpunkt
            nochmals zu versuchen.
          </p>
        </ContentBox>
      );
    }
    return null;
  }

  render() {
    const masterData = this.props.masterStore.masterData;
    const { digitalHealthStore, tilesStore } = this.props;
    let someCanCheckin = masterData.travelParty.some((m) => m.checkinEnabled);
    const someCouldCheckin = masterData.travelParty.some((m) => m.checkinAvailable && !m.checkinCompleted);
    const allNoneSchengen = masterData.travelParty.every((m) => m.schengenResident === false);

    if (tilesStore.isLoading) {
      return <LoadingIndicator />;
    }
    const { tiles } = tilesStore;
    // see the rules for displaying the box schengenResident
    // https://confluence.cellular.de/display/tuicdocmrl/Schiffsmanifest+overview
    const showCheckInBox = masterData.checkinEnabled && someCouldCheckin && !allNoneSchengen;
    const { partyMember, url, showPinModal, error } = this.state;

    // console.log('SURVEY_NOT_AVAILABLE :', masterData.digitalHealthStateNotAvailable);
    // console.log(digitalHealthStore.getQuestions);

    // TUICUNIT-2856 if tilesconfig enable ignore other state
    if (tiles.onlineCheckIn.enabled === false) {
      someCanCheckin = false;
    }

    return (
      <div className="page payment">
        <Text type="h1" className="mb-6">
          Zahlungsmittel
        </Text>

        {this.renderTravelPartyList()}

        {showPinModal && (
          <PinDialog
            url={url}
            partyMember={partyMember}
            error={error}
            onClose={() => {
              this.setState({
                showPinModal: false,
                error: null,
              });
            }}
          />
        )}
      </div>
    );
  }
}
