// @flow

import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { autobind } from 'core-decorators';

import ModalFilter from '../ModalFilter';
import DailyCatalogList from './DailyCatalogList';

import type CatalogEventDaysList from '../../models/CatalogEventDaysList';
import type Booking from '../../models/Booking';

type Props = {
  list: CatalogEventDaysList,
  categoryFilter: { id: number, name: string }[],
  bookings: { [string]: Booking[] },
  onSelectDay?: (timeStamp: number) => void,
  selectedCategories?: number[] | null,
  onSelectCategories?: (selectedCategories: number[]) => void,
};

@observer
export default class EventDayList extends Component<Props> {
  componentDidMount() {
    const { list, selectedCategories } = this.props;
    if (selectedCategories) {
      list.selectCategoryIds(selectedCategories);
    }
  }

  @autobind
  selectDay(timeStamp: number) {
    const { list, onSelectDay } = this.props;
    list.selectDay(timeStamp);
    onSelectDay && onSelectDay(timeStamp);
  }

  @autobind
  selectCategoryIds(cats: number[]) {
    const { list, onSelectCategories } = this.props;
    list.selectCategoryIds(cats);
    onSelectCategories && onSelectCategories(cats);
  }

  @computed
  get categoryFilterOptions(): { label: string, value: number }[] {
    const { categoryFilter } = this.props;
    return categoryFilter.slice().map((cat) => ({
      label: cat.name,
      value: cat.id,
    }));
  }

  @computed
  get dayFilterOptions(): { label: string, value: number }[] {
    const { list } = this.props;
    return list.days.map((day) => ({
      label: day.displayDate,
      value: day.date.getTime(),
    }));
  }

  renderFilters() {
    const { list } = this.props;
    return (
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 mb-4">
        <div>
          <ModalFilter
            name="day"
            label="Nach Reisetag filtern"
            triggerLabel="Alle Reisetage"
            anyLabel="Alle Reisetage"
            defaultValue={list.filteredDay}
            options={this.dayFilterOptions}
            onChange={this.selectDay}
          />
        </div>
        <div>
          <ModalFilter
            multiselect
            label="Nach Kategorie filtern"
            triggerLabel="Kategorien"
            anyLabel="Alle Kategorien"
            options={this.categoryFilterOptions}
            defaultValue={list.filteredCategoryIds.slice()}
            onChange={this.selectCategoryIds}
          />
        </div>
      </div>
    );
  }

  render() {
    const { list, bookings } = this.props;

    return (
      <div>
        {this.renderFilters()}
        <DailyCatalogList days={list.filteredDays} bookings={bookings} />
      </div>
    );
  }
}
