// @flow
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { routerShape } from 'react-router/lib/PropTypes';
import { observable, action } from 'mobx';
import { autobind } from 'core-decorators';
import groupBy from 'lodash/groupBy';

import bookingApi from '../../../api/bookings';
import preload from '../../../components/pages/decorators/preload';
import requirements from '../../../components/pages/decorators/requirements';
import CheckInProcessForm from '../../../components/CheckIn/Process/Form';
import CheckInProcessSummary from '../../../components/CheckIn/Process/Summary';
import track from '../../../tracking';

import CheckInRequest from '../../../models/CheckInRequest';
import pinStorage from '../../../utils/pinStorage';

import {
  MASTERDATA_STATUS_CHECKIN_ALREADY_CHECKED_IN,
  MASTERDATA_STATUS_CHECKIN_NOT_SCHENGEN_RESIDENT,
  MASTERDATA_STATUS_CHECKIN_MANIFEST_NOT_COMPLETED,
  MASTERDATA_STATUS_CHECKIN_PAYMENT_NOT_COMPLETED,
  MASTERDATA_STATUS_CHECKIN_MANIFEST_AND_PAYMENT_NOT_COMPLETED,
  MASTERDATA_STATUS_CHECKIN_DATA_PRELIMINARY,
  MASTERDATA_STATUS_CHECKIN_MANIFEST_DATA_PRELIMINARY,
} from '../../../config/constants';

import type MasterStore from '../../../stores/MasterStore';
import type PaymentStore from '../../../stores/PaymentStore';
import type CheckInStore from '../../../stores/CheckInStore';
import type PartyMember from '../../../models/PartyMember';
import type TimeSloteStore from '../../../stores/TimeSloteStore';

type Props = {
  masterStore: MasterStore,
  checkInStore: CheckInStore,
  paymentStore: PaymentStore,
  timeSloteStore: TimeSloteStore,
};

@inject('paymentStore', 'checkInStore', 'timeSloteStore')
@preload({ masterStore: 'MasterStore' })
@requirements(['checkinEnabled'])
@observer
export default class PageCheckInProcess extends Component<Props> {
  static breadcrumb = 'Online Check-in';

  static contextTypes = {
    router: routerShape,
  };

  checkInRequest: CheckInRequest;
  @observable showSummary = false;

  constructor(props: Props) {
    super(props);
    this.checkInRequest = new CheckInRequest();
    track.pageView(window.location.pathname, ['online-check-in', 'check-in'], 'content');
  }

  @action.bound
  handleFormSubmit() {
    const { paymentStore } = this.props;
    const { travelParty } = this.props.masterStore.masterData;

    paymentStore.fetchPaymentInfos(
      travelParty.map((m) => (pinStorage.getPin(m.mpi) ? m.mpi : null)).filter((mpi) => !!mpi)
    );
    this.showSummary = true;
  }

  // TUICUNIT-1993: fuck timeSlote Event booking cascade
  @autobind
  handleSummarySubmit() {
    if (this.checkInRequest.formData.selectedMembers) {
      // case:
      // booking not working: fallback data is set
      // and user click directly button "Jetzt online einchecken"
      // need to inject fallback data into selectedMembers array
      // because we need this for this fucking event booking
      this.checkInRequest.backUpData = JSON.parse(JSON.stringify(this.checkInRequest.formData.selectedMembers));

      const needTimeSlote = [];
      this.checkInRequest.formData.selectedMembers.forEach((m) => {
        if (m.timeSlote && m.timeSlote.state === true) {
          const storeData = JSON.parse(JSON.stringify(m));
          if (storeData.timeSlote.booked) {
            this.setTimeSloteMember({
              mpi: storeData.mpi,
              timeSlote: storeData.timeSlote.booked,
            });
          } else {
            needTimeSlote.push(storeData.timeSlote.data);
          }
        }
      });

      let selectedMembers = null;
      if (needTimeSlote.length !== 0) {
        selectedMembers = JSON.parse(JSON.stringify(this.checkInRequest.formData.selectedMembers));
        this.cleanTimeSloteMember();
      }

      if (needTimeSlote.length !== 0) {
        this.checkInRequest.markAsSending();
        bookingApi
          .save(this.props.masterStore.user, this.props.masterStore.masterData, {
            toBook: needTimeSlote,
          })
          .then((response) => {
            if (response.failed && response.failed.length > 0) {
              response.failed.forEach((rs) => {
                rs.mpis.forEach((mpi) => {
                  const findMemberData = selectedMembers.find((s) => s.mpi === mpi);
                  if (findMemberData) {
                    this.setTimeSloteMember({
                      mpi: mpi,
                    });
                  }
                });
              });
            }
            if (response.succeeded && response.succeeded.length > 0) {
              response.succeeded.forEach((rs) => {
                rs.mpis.forEach((mpi) => {
                  const findMemberData = selectedMembers.find((s) => s.mpi === mpi);
                  if (findMemberData) {
                    this.setTimeSloteMember({
                      mpi: mpi,
                      timeSlote: {
                        bookingId: rs.bookingId,
                        startDate: findMemberData.timeSlote.startDate,
                        endDate: findMemberData.timeSlote.endDate,
                      },
                    });
                  }
                });
              });
            }
            this.handleSummarySubmitPlain();
          });
      } else {
        this.handleSummarySubmitPlain();
      }
    }
  }

  @action
  cleanTimeSloteMember() {
    this.checkInRequest.formData.selectedMembers.forEach((item, index) => {
      if (item.timeSlote && !item.timeSlote.bookingId) {
        this.checkInRequest.formData.selectedMembers[index].timeSlote = null;
      }
    });
  }

  @action
  setTimeSloteMember(value) {
    this.checkInRequest.formData.selectedMembers.forEach((item, index) => {
      if (item.mpi === value.mpi && value.timeSlote) {
        this.checkInRequest.formData.selectedMembers[index].timeSlote = value.timeSlote;
      }
    });
  }

  @action
  resetTimeSloteMember(index, value) {
    this.checkInRequest.formData.selectedMembers[index].timeSlote = value;
  }

  /* TUICUNIT-3368: Check-in for Groups (@_@) */
  @action
  cleanSubmitData() {
    const cleanData = this.checkInRequest.formData;
    if (cleanData) {
      this.checkInRequest.formData = null;
      this.checkInRequest.formData = {
        email: cleanData.email,
        mobile: cleanData.mobile,
        isGroupBooking: cleanData.isGroupBooking,
        selectedMembers: [],
      };
      cleanData.selectedMembers.map((item) => {
        const member = {
          mpi: item.mpi,
        };
        if (item.arrivalAt && !cleanData.isGroupBooking) {
          member.arrivalAt = item.arrivalAt;
        }
        if (item.timeSlote && !cleanData.isGroupBooking) {
          member.timeSlot = item.timeSlote;
        }
        this.checkInRequest.formData.selectedMembers.push(member);
      });
    }
  }

  handleSummarySubmitPlain() {
    this.cleanSubmitData();

    this.props.checkInStore.handleCheckInRequest(this.checkInRequest).then(() => {
      if (this.checkInRequest.backUpData) {
        this.checkInRequest.backUpData.forEach((fm) => {
          this.checkInRequest.formData.selectedMembers.forEach((m, index) => {
            if (m.mpi === fm.mpi && !m.timeSlote) {
              this.resetTimeSloteMember(index, fm.timeSlote);
            }
          });
        });
      }
      if (this.checkInRequest.sendError) {
        // this.checkInRequest.resetSelectedMembers();
        window.scrollTo(0, 0);
        return;
      }
      this.checkInRequest.resetSelectedMembers();
      this.context.router.replace(`/checkin/checkin/success`);
    });
  }

  @action
  resetErrorMessage() {
    this.checkInRequest.sendError = null;
  }

  /*
  @autobind
  handleSummarySubmit() {
    this.props.checkInStore
      .handleCheckInRequest(this.checkInRequest)
      .then(() => {
        if (this.checkInRequest.sendError) {
          window.scrollTo(0, 0);
          return;
        }
        this.checkInRequest.resetSelectedMembers();
        this.context.router.replace(`/checkin/checkin/success`);
      });
  }
  */
  @action.bound
  handleResetSummary() {
    this.showSummary = false;
  }

  getCheckinStatusText(reason: ?string): string {
    switch (reason) {
      case MASTERDATA_STATUS_CHECKIN_MANIFEST_AND_PAYMENT_NOT_COMPLETED:
      case MASTERDATA_STATUS_CHECKIN_MANIFEST_NOT_COMPLETED:
      case MASTERDATA_STATUS_CHECKIN_PAYMENT_NOT_COMPLETED:
        return 'Können nicht eingecheckt werden, da ihr Schiffsmanifest nicht vollständig ist:';
      case MASTERDATA_STATUS_CHECKIN_NOT_SCHENGEN_RESIDENT:
        return 'Können nicht eingecheckt werden, da sie nicht Bürger eines Schengen-Landes sind:';
      case MASTERDATA_STATUS_CHECKIN_MANIFEST_DATA_PRELIMINARY:
        return 'Können nicht eingecheckt werden, da sich die Schiffsmanifestdaten in Bearbeitung befinden.';
      default:
        return 'Können nicht eingecheckt werden.';
    }
  }

  get notCheckinableMembers(): {
    reason: string,
    reasonText: string,
    travelParty: PartyMember[],
  }[] {
    const { travelParty } = this.props.masterStore.masterData;

    let notCheckinableMembers = travelParty.filter(
      (partyMember) =>
        !partyMember.checkinEnabled &&
        ![MASTERDATA_STATUS_CHECKIN_ALREADY_CHECKED_IN, MASTERDATA_STATUS_CHECKIN_DATA_PRELIMINARY].includes(
          partyMember.checkinStatusReason
        )
    );
    notCheckinableMembers = groupBy(
      notCheckinableMembers,
      (partyMember: PartyMember) => partyMember.checkinStatusReason
    );
    return Object.keys(notCheckinableMembers).map((key) => ({
      reason: key,
      reasonText: this.getCheckinStatusText(key),
      travelParty: notCheckinableMembers[key],
    }));
  }

  render() {
    const { paymentStore, timeSloteStore } = this.props;
    const { travelParty } = this.props.masterStore.masterData;
    const checkinableMembers = travelParty.filter((partyMember) => partyMember.checkinEnabled);
    const checkinCompletedMembers = travelParty.filter((partyMember) => partyMember.checkinCompleted);

    console.log(travelParty);

    if (this.showSummary) {
      return (
        <CheckInProcessSummary
          checkInRequest={this.checkInRequest}
          membersReadyToCheckIn={checkinableMembers}
          travelParty={travelParty}
          groupBooking={this.props.masterStore.masterData.groupBooking}
          paymentInfos={paymentStore.paymentInfosFor(this.checkInRequest.mpisToCheckIn)}
          paymentInfosLoaded={paymentStore.paymentInfosLoaded}
          onSubmit={this.handleSummarySubmit}
          onResetCheckIn={this.handleResetSummary}
        />
      );
    }

    this.resetErrorMessage();

    return (
      <span>
        {timeSloteStore.isLoading}
        <CheckInProcessForm
          masterData={this.props.masterStore.masterData}
          checkInRequest={this.checkInRequest}
          membersReadyToCheckIn={checkinableMembers}
          membersCompletedCheckIn={checkinCompletedMembers}
          membersNotReadyToCheckIn={this.notCheckinableMembers}
          timeSloteStore={timeSloteStore}
          onSubmit={this.handleFormSubmit}
        />
      </span>
    );
  }
}
