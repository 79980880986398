// @flow
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';

import DailyEventPrice from './DailyEventPrice';
import ParticipantList from './ParticipantList';
import Price from '../Price';
import Button from '../Button';
import MultiSelect from '../form/MultiSelect';

import type DailyEvent from '../../models/DailyEvent';
import type PartyMember from '../../models/PartyMember';
import type DailyEventBookingRequest from '../../models/BookingRequest/DailyEventBookingRequest';

type Props = {
  bookable: DailyEvent,
  bookingRequest: DailyEventBookingRequest,
  travelParty: PartyMember[],
  onBooking: (DailyEventBookingRequest) => any,
  onChangeBooking: (DailyEventBookingRequest) => any,
  onCancel: () => any,
  showParticipants?: boolean,
};

type State = {
  showParticipants: boolean,
};
/**
 * This is basically the same as ExcursionBookingAssistant, but has no isBaby checks and no externalOperator stuff
 */
@observer
export default class DailyEventBookingAssistant extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showParticipants: !!props.showParticipants,
    };
  }

  @autobind openParticipants() {
    this.setState({ showParticipants: true });
  }

  @autobind handleChangeInParty(mpi: number, value: boolean) {
    this.props.bookingRequest.selectParticipants(mpi, !!value);
  }

  @autobind handleBookingClick() {
    const { onBooking, onChangeBooking, bookable, bookingRequest } = this.props;

    if (bookable.isBooked) {
      if (onChangeBooking) onChangeBooking(bookingRequest);
    } else {
      if (onBooking) onBooking(bookingRequest);
    }
  }

  @autobind handleCancelClick() {
    const { onCancel } = this.props;
    if (onCancel) onCancel();
  }

  renderParticipantChoice() {
    const { travelParty, bookingRequest, bookable } = this.props;

    const options = travelParty.map((member) => ({
      value: member.mpi,
      label: member.displayName,
    }));

    return (
      <div>
        <h2>Teilnehmer wählen:</h2>
        <MultiSelect
          options={options}
          onChange={this.handleChangeInParty}
          value={bookingRequest.selectedMpis.slice()}
        />

        <div className="l-right flex flex-col items-end">
          {bookingRequest.total !== null && bookingRequest.total !== undefined && (
            <div className="l-mod-sub">
              <Price price={`${bookingRequest.total} €`} label="Gesamtpreis" />
            </div>
          )}
          <Button dark big className="l-mod-sub" disabled={!bookingRequest.isReady} onClick={this.handleBookingClick}>
            {bookable.isBooked ? 'Änderung speichern' : 'Jetzt reservieren'}
          </Button>
        </div>
      </div>
    );
  }

  renderDisabledButton() {
    const { bookable } = this.props;
    return (
      <Button className="l-mod-sub" disabled dark big>
        {bookable.bookingStatus}
      </Button>
    );
  }

  renderBookedView() {
    const { travelParty, bookable } = this.props;

    return (
      <div className="l-right">
        <ParticipantList travelParty={travelParty} bookable={bookable} />
        <div className="flex flex-col gap-4 items-end">
          {bookable.isBookable ? (
            <Button
              className="l-mod-sub"
              dark
              big
              onClick={this.openParticipants}
              disabled={!bookable.booking.cancelable || bookable.booking.prelimenary}
            >
              Reservierung ändern
            </Button>
          ) : (
            this.renderDisabledButton()
          )}
          <Button
            onClick={this.handleCancelClick}
            disabled={!bookable.booking.cancelable || bookable.booking.prelimenary}
          >
            Reservierung stornieren
          </Button>
        </div>
      </div>
    );
  }

  renderUnbookedView() {
    const { bookable } = this.props;

    return (
      <div className="l-right flex flex-col items-end">
        <DailyEventPrice price={bookable.retailPrice} />
        {bookable.isBookable ? (
          <Button className="l-mod-sub" dark big onClick={this.openParticipants}>
            Zur Reservierung
          </Button>
        ) : (
          this.renderDisabledButton()
        )}
      </div>
    );
  }

  renderObligatoryView() {
    const { travelParty, bookable } = this.props;

    return (
      <div className="l-right">
        <ParticipantList travelParty={travelParty} bookable={bookable} />
      </div>
    );
  }

  render() {
    const { bookable } = this.props;

    if (this.state.showParticipants) {
      return this.renderParticipantChoice();
    }
    if (bookable.highlight) {
      return this.renderObligatoryView();
    } else {
      if (bookable.isBooked) {
        return this.renderBookedView();
      } else {
        return this.renderUnbookedView();
      }
    }
  }
}
