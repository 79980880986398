// @flow
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import type TravelDocumentsStore from '../../../stores/TravelDocumentsStore';
import type MasterStore from '../../../stores/MasterStore';
import Headline from '../../Headline';
import LoadingIndicator from '../../LoadingIndicator';
import TravelDocumentsList from '../../BookingDetails/TravelDocumentsList';
import ErrorMessage from '../../../components/form/ErrorMessage';
import track from '../../../tracking';

type Props = {
  travelDocumentsStore: TravelDocumentsStore,
  masterStore: MasterStore,
};

@inject('travelDocumentsStore', 'masterStore')
@observer
export default class TravelDocuments extends Component<Props> {
  static breadcrumb = 'Reiseunterlagen';

  componentDidMount() {
    const { travelDocumentsStore } = this.props;
    travelDocumentsStore.fetchData();
    track.pageView(window.location.pathname, ['buchung-reiseunterlagen', 'reiseunterlagen'], 'content');
  }

  render() {
    const { travelDocumentsStore, masterStore } = this.props;

    if (travelDocumentsStore.isLoading) {
      return <LoadingIndicator />;
    }
    if (travelDocumentsStore.hasError) {
      return (
        <div className="page page-travel-documents error">
          <ErrorMessage error={travelDocumentsStore.error.text} />
        </div>
      );
    }
    const { documents, texte } = travelDocumentsStore;
    const { masterData } = masterStore;

    return (
      <div className="page page-travel-documents">
        <Headline
          className="l-mod"
          title={(texte && texte.headline) || ''}
          subtitle={masterData.subtitleLine}
        />
        <div className="l-row group">
          <div className="l-col double">
            <TravelDocumentsList
              documents={documents}
              processNumber={masterData.processNumber}
              travelDocumentsStore={travelDocumentsStore}
              texte={texte}
            />
          </div>
        </div>
      </div>
    );
  }
}
