// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import NotFound from './NotFound';
import Headline from '../Headline';
import HeroImage from '../HeroImage';
import LoadingIndicator from '../LoadingIndicator';
import CatalogTile from '../tiles/CatalogTile';
import InfoBox from '../InfoBox';
import LinkAsButton from '../LinkAsButton';

import type HighlightStore from '../../stores/HighlightStore';
import track from '../../tracking';

type Props = {
  highlightStore: HighlightStore,
};

@inject('highlightStore')
@observer
export default class PageHighlights extends Component<Props> {
  static breadcrumb = 'Höhepunkte an Bord';

  componentDidMount() {
    const { highlightStore, params } = this.props;
    highlightStore.fetch(params.highlightId);
  }

  componentDidUpdate() {
    const { highlightStore } = this.props;
    if (!highlightStore.isLoading) {
      const { highlights } = highlightStore;
      track.pageView(
        window.location.pathname,
        [
          PageHighlights.breadcrumb,
          highlights.headline,

        ],
      );
    }
  }

  render() {
    const { highlightStore } = this.props;
    if (highlightStore.isLoading) return <LoadingIndicator />;
    if (highlightStore.isNotFound) return <NotFound />;
    const { highlights } = highlightStore;
    return (
      <div className="page">
        <Headline title={highlights.headline} />
        <HeroImage src={highlights.imageSrc} alt="" className="l-mod-sub" />
        <div dangerouslySetInnerHTML={{ __html: highlights.text }} />

        {highlights.infoBox ? (
          <div>
            <br />
            <InfoBox noIcon>
              <div>
                <strong>{highlights.infoBox.headline}</strong>
              </div>
              <br />
              <div dangerouslySetInnerHTML={{ __html: highlights.infoBox.text }} />

              {highlights.infoBox.pdfs ? (
                <div className="spacer-top">
                  {highlights.infoBox.pdfs.map((pdf, i) => (
                    <p key={i}>
                      <LinkAsButton dark newPage link={pdf.url}>
                        {pdf.title}
                      </LinkAsButton>
                    </p>
                  ))}
                </div>
              ) : null}
            </InfoBox>
            <br />
          </div>
        ) : null}

        {highlights.links ? (
          <div className="l-tiles">
            {highlights.links.map((highlight, index) => (
              <CatalogTile
                key={`${highlight.venueId}-${index}`}
                link={highlight.link}
                imageSrc={highlight.imageSrc}
                title={highlight.name}
                description={highlight.displayTime}
              />
            ))}
          </div>
        ) : null}
      </div>
    );
  }
}
