var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Link } from 'react-router';
import { twMerge } from 'tailwind-merge';
import isInAppView from '../../utils/isInAppView';
import { IconArrowLeft } from './icons/IconArrowLeft';
var BreadcrumbAction = function (props) {
    return (React.createElement(Link, __assign({}, props, { to: props.href, className: twMerge('bg-white size-5 rounded-full text-ocean-blue-120 p-2 flex items-center justify-center', props === null || props === void 0 ? void 0 : props.className) })));
};
export var Breadcrumb = function (_a) {
    var backAnchor = _a.backAnchor, _b = _a.actions, actions = _b === void 0 ? {} : _b, children = _a.children;
    return (React.createElement("div", { className: "flex w-full items-center gap-4 mb-6 px-2 pt-2 xl:mb-12" },
        React.createElement("div", null, !!backAnchor && !isInAppView && (React.createElement(BreadcrumbAction, __assign({}, backAnchor),
            React.createElement(IconArrowLeft, null)))),
        React.createElement("div", { className: "text-high-emphasis grow text-center text-sm" }, children),
        React.createElement("div", { className: "min-w-9 flex gap-2" }, Object.entries(actions).map(function (_a) {
            var key = _a[0], value = _a[1];
            return (React.createElement(BreadcrumbAction, __assign({ key: key }, value)));
        }))));
};
