// @flow
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable, action, computed } from 'mobx';
import { autobind } from 'core-decorators';
import LoadingIndicator from '../../components/LoadingIndicator';
import BreadcrumbLink from '../breadcrumbs/BreadcrumbLink';
import ErrorMessage from '../../components/form/ErrorMessage';
import Headline from '../Headline';
import HeroImage from '../HeroImage';
import InfoBox from '../InfoBox';
import FormSelect from '../form/Select';
import CatalogTile from '../../components/tiles/CatalogTile';
import Duration from '../../components/Duration';
import track from '../../tracking'
import { DEFAULT_FILTER } from '../../stores/SpaBeautySalonStore';
import type { SpaTeaserStore } from '../../stores/SpaTeaserStore';
import type { SpaBeautySalonStore } from '../../stores/SpaBeautySalonStore';

type Props = {
  spaTeaserStore: SpaTeaserStore,
  spaBeautySalonStore: SpaBeautySalonStore,
};
@inject('spaTeaserStore')
@observer
class BeautySalonBreadcrumb extends Component<{
  spaTeaserStore: SpaTeaserStore,
}> {
  componentDidMount() {
    this.props.spaTeaserStore.fetch();
  }

  render() {
    const { spaTeaserStore } = this.props;

    let title = '';
    if (spaTeaserStore && !spaTeaserStore.error) {
      const data = spaTeaserStore.data.find(i => {
        if (i.type === 'beautysalon') {
          return i;
        }
      });
      if (data && data.name) {
        title = data.name;
      } else {
        title = 'Kosmetikstudio';
      }
    }
    return <BreadcrumbLink {...this.props} text={title} />;
  }
}

@inject('spaBeautySalonStore', 'spaTeaserStore')
@observer
export default class BeautySalonView extends Component<Props> {
  static breadcrumb = BeautySalonBreadcrumb;

  @observable selectedCategory: ?string;
  @observable filterCategories: ?Array;

  componentDidMount() {
    this.props.spaBeautySalonStore.fetch();
    // TODO: replace kosmetikstudio with the breadcrumb title
    track.pageView(window.location.pathname, ['schoenheit-gesundheit', 'kosmetikstudio'], 'sub-category');
  }

  @autobind
  @action
  selectCategory(category: string) {
    this.selectedCategory = category;
  }

  @action
  setFilterCategories(categorys: Array) {
    this.filterCategories = categorys;
  }

  @computed
  get filterOptions(): { label: string, value: string }[] {
    if (!Array.isArray(this.filterCategories)) {
      return [];
    }
    return this.filterCategories.map(category => ({
      label: category,
      value: category,
    }));
  }

  @action
  showFilter() {
    return (
      Array.isArray(this.filterCategories) && this.filterCategories.length > 1
    );
  }

  renderFilter() {
    if (this.showFilter()) {
      return (
        <FormSelect
          name="category"
          label="Wählen Sie aus dem folgenden Angebot:"
          value={this.selectedCategory}
          onChange={this.selectCategory}
          options={this.filterOptions}
        />
      );
    }
  }

  render() {
    const { spaTeaserStore, spaBeautySalonStore } = this.props;
    if (spaBeautySalonStore.isLoading) {
      return <LoadingIndicator />;
    }
    if (spaBeautySalonStore.error) {
      return (
        <div className="page error">
          <ErrorMessage
            error={
              'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.'
            }
          />
        </div>
      );
    }
    this.setFilterCategories(spaBeautySalonStore.data.filter.categories);

    let teaserData = null;
    if (spaTeaserStore && !spaTeaserStore.error) {
      teaserData = spaTeaserStore.data.find(i => {
        if (i.type === 'beautysalon') {
          return i;
        }
      });
    }

    let showOffer = spaBeautySalonStore.data.spaOffers;
    if (this.selectedCategory && this.selectedCategory !== DEFAULT_FILTER) {
      showOffer = spaBeautySalonStore.data.spaOffers.filter(
        i => i.category === this.selectedCategory
      );
    }

    return (
      <div className="page">
        <Headline
          title={(teaserData && teaserData.name) || ''}
          icon="spiegel"
        />
        <HeroImage
          src={teaserData && teaserData.imageUri}
          alt=""
          className="l-mod-sub"
        />
        <div className="l-row">
          <div className="l-col double">
            <p
              dangerouslySetInnerHTML={{
                __html: teaserData && teaserData.description,
              }}
            />
            {!!spaBeautySalonStore.data.hint && !this.showFilter() ? (
              <InfoBox noIcon>
                <strong>Für Bordbegeisterte:</strong> Profitieren Sie während
                der Liegezeiten an Hafentagen von einem Preisvorteil auf
                ausgewählte Leistungen.
              </InfoBox>
            ) : null}
          </div>
        </div>

        {this.renderFilter() || <h1>Übersicht aller Anwendungen</h1>}
        <div className="l-tiles">
          {showOffer.map(offer => (
            <CatalogTile
              key={offer.spaId}
              description={offer.category}
              booking={offer.isBooked && offer}
              link={`/schoenheit-und-gesundheit/beautysalon/${offer.spaId}`}
              imageSrc={offer.image}
              title={offer.title}
              footer={<Duration duration={offer.duration} />}
              price={offer.price}
              isStartingPrice={offer.isStartingPrice}
            />
          ))}
        </div>
      </div>
    );
  }
}
