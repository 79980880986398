// @flow

import React, { Component } from 'react';

import Rating from '../Rating';
import CatalogTile from '../tiles/CatalogTile';

import type Excursion from '../../models/Excursion';
import type Booking from '../../models/Booking';
import { getSlideDirectionString } from '../../utils/sliderHelper';
import tracking from '../../tracking';
import { IVipStatus } from '../../types/bookable';
import { inject } from 'mobx-react';
import type { TrackingExcursion } from '../../types/tracking';
import { tealiumUnixTimeToDate, tealiumValue } from '../../utils/tracking';

type Props = {
  urlBase: string,
  excursions?: Excursion[],
  bookings: Booking[],
  vip?: IVipStatus,
};

@inject('breadcrumbRouterStore', 'masterStore')
export default class ExcursionList extends Component<Props> {
  bookingMap = {};
  triggered = false;

  constructor(props, context) {
    super(props, context);
    Array.isArray(props.bookings) &&
      props.bookings.forEach((booking) => (this.bookingMap[parseInt(booking.typeId, 10)] = booking));
  }

  // TUICUNIT-2638: waiting list
  render() {
    const { excursions, urlBase, vip } = this.props;
    const excursionsToShow = excursions || [];

    if (this.triggered === false) {
      this.triggered = true;
      const excursions: TrackingExcursion[] = excursionsToShow.map((item: Excursion) => ({
        code: item.bookingCode,
        name: item.name,
        discount: '0.00',
        startDate: tealiumUnixTimeToDate(item.departureTime),
        value: tealiumValue(
          item.priceTypes.getPrice(
            this.props.masterStore.masterData.requestor.guestType.priceModel,
            false,
            item.isPriceBulk
          )
        ),
        quantity: '1',
        operator: {
          code: item.operators[0].operatorCode,
          name: item.operators[0].operatorName,
        },
        categoryId: item.categoryIds[0],
        category: item.category,
      }));

      tracking.viewListItem(this.props.breadcrumbRouterStore, excursions);
    }

    if (excursionsToShow.length === 0) {
      return (
        <div className="l-row">
          <div className="l-col double">
            <p>
              Die von Ihnen getroffene Auswahl liefert leider kein Ergebnis. Bitte ändern Sie die Filtereinstellungen
              und versuchen es erneut.
            </p>
          </div>
        </div>
      );
    }
    return (
      <div className="l-tiles napp m-0">
        {excursionsToShow.map((excursion, index) => (
          <div
            key={excursion.id}
            onClick={() =>
              tracking.setCurrentListItem(index + 1, this.props.breadcrumbRouterStore, {
                categoryId: excursion.categoryIds[0],
                category: excursion.category,
              })
            }
          >
            <CatalogTile
              booking={this.bookingMap[excursion.id]}
              link={`${urlBase}/${excursion.id}`}
              imageSrc={excursion.image}
              images={excursion.images}
              onSlideShowChange={(oldIndex, newIndex) => {
                tracking.event(
                  'Landausflug',
                  `Listingseite_Slider_${getSlideDirectionString(oldIndex, newIndex)}`,
                  excursion.name
                );
              }}
              title={excursion.name}
              nappStyle={true}
              unavailableBadge={!excursion.isBookableForRequestor(vip) ? excursion.getBadgeText(vip) : ''}
              imageBadge={excursion.operatorClassName ? <div className={excursion.operatorClassName} /> : null}
              footer={
                <div>
                  {excursion.timeOfDay}, {excursion.duration}
                  <br />
                  Ausflugscode: {excursion.bookingCode}
                  <br />
                  <Rating label="Level" value={excursion.difficultyNumber} />
                  Verfügbarkeit: {excursion.vacancy > 20 ? '> 20' : `${excursion.vacancy || 0}`}
                </div>
              }
              price={excursion.price}
              isBookedBadge={
                this.bookingMap[excursion.id] && this.bookingMap[excursion.id].isWaitListed === true
                  ? 'Warteliste'
                  : null
              }
              showPrice={false}
            />
          </div>
        ))}
      </div>
    );
  }
}
