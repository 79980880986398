// @flow
import React, { Component } from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';

import preload from '../decorators/preload';
import LoadingIndicator from '../../LoadingIndicator';
import ErrorMessage from '../../../components/form/ErrorMessage';
import LinkAsButton from '../../../components/LinkAsButton';
import storage from '../../../utils/storage';
import track from '../../../tracking';

import type MasterStore from '../../../stores/MasterStore';

import { routerShape } from 'react-router/lib/PropTypes';

import type { FlightPreferenceStore, VipPartyMemberType } from '../../../stores/FlightPreferenceStore';

import SurveyView from '../../BookingDetails/FlightPreference/Survey';
import {
  SURVEY_MPI_OK,
  SURVEY_MPI_NOT_OK,
  SURVEY_MPI_CONFIRMATION_REQUIRED,
  SURVEY_CLOSED,
} from '../../../config/constants';

type Props = {
  masterStore: MasterStore,
  flightPreferenceStore: FlightPreferenceStore,
  params: Object,
};

@inject('masterStore', 'flightPreferenceStore')
@preload({ masterStore: 'MasterStore' })
@observer
export default class PageFlightPreference extends Component<Props> {
  static breadcrumb = 'Flugwünsche';

  static contextTypes = {
    router: routerShape,
  };

  componentDidMount() {
    const { flightPreferenceStore, params } = this.props;
    // fucking reload
    if (!this.currentFPMember) {
      flightPreferenceStore.setupAfterReload(params.memberIndex);
    } else {
      flightPreferenceStore.fetch(this.currentFPMember.mpi);
    }
    this.trackPageView();
  }

  trackPageView = () => {
    const { params } = this.props;
    track.pageView(
      window.location.pathname,
      ['buchung-reiseunterlagen', 'vip-tarif', 'vip-gaeste', `gast-${params.memberIndex}`],
      'content'
    );
  };

  @computed
  get currentFPMember(): ?VipPartyMemberType {
    const { params, flightPreferenceStore } = this.props;

    const currentList = flightPreferenceStore.getVipTravelParty;
    const current = currentList[params.memberIndex] || null;

    return current;
  }

  render() {
    // const isInAppView = this.props.masterStore.isInAppView;
    const { masterStore, flightPreferenceStore } = this.props;
    if (flightPreferenceStore.isLoading) return <LoadingIndicator />;

    const currentFPMember = this.currentFPMember;

    if (!currentFPMember) {
      return (
        <div className="page survey error">
          <ErrorMessage error={flightPreferenceStore.error.text} />
        </div>
      );
    }

    if (flightPreferenceStore.hasError) {
      return (
        <div className="page survey error">
          <ErrorMessage error={flightPreferenceStore.error.text} />
        </div>
      );
    }

    if (flightPreferenceStore.getQuestions && !flightPreferenceStore.getQuestions[0]) {
      return (
        <div className="page survey error">
          <ErrorMessage error={flightPreferenceStore.error.text} />
        </div>
      );
    }

    const memberName = masterStore.masterData.getPartyMember(currentFPMember.mpi).displayName;

    let viewResult = false;
    if (
      currentFPMember.status === SURVEY_MPI_OK ||
      currentFPMember.status === SURVEY_MPI_NOT_OK ||
      currentFPMember.status === SURVEY_MPI_CONFIRMATION_REQUIRED ||
      currentFPMember.status === SURVEY_CLOSED
    ) {
      viewResult = true;
    }

    return (
      <div className="page survey flight">
        <SurveyView
          currentFPMember={currentFPMember}
          flightPreferenceStore={flightPreferenceStore}
          inProgress={flightPreferenceStore.inProgress}
          viewResult={viewResult}
          memberName={memberName}
          lang={storage.get('language') || 'de'}
        />
        <div className="l-row">
          <div className="l-col double l-right">
            <LinkAsButton className="back button" link={'/buchungsdetails/vip-tarif/1/vip-gaeste'}>
              zurück
            </LinkAsButton>
          </div>
        </div>
        <br />
      </div>
    );
  }
}
