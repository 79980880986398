import React from 'react';
import { Link } from 'react-router';
import tracking from '../../tracking';
import { twMerge } from 'tailwind-merge';
export var Tile = function (_a) {
    var Icon = _a.icon, _b = _a.hideIconOnMobile, hideIconOnMobile = _b === void 0 ? false : _b, title = _a.title, badge = _a.badge, onClick = _a.onClick, link = _a.link, linkIsExternal = _a.linkIsExternal, disabled = _a.disabled, children = _a.children;
    var Component = (onClick || link) && !disabled ? Link : 'div';
    var handleClick = function (event) {
        if (disabled)
            return;
        if (title) {
            tracking.click('content.tile.' + title);
        }
        else {
            tracking.click('content.tile');
        }
        onClick === null || onClick === void 0 ? void 0 : onClick(event);
    };
    return (React.createElement(Component, { to: link, target: linkIsExternal ? '_blank' : undefined, className: twMerge('grow flex md:flex-col bg-white px-3 py-4 md:py-9 justify-center items-center gap-2', (!!link || !!onClick) && 'cursor-pointer', disabled && 'cursor-not-allowed'), onClick: handleClick, disabled: disabled },
        !!Icon && (React.createElement(Icon, { className: twMerge('size-10', hideIconOnMobile && 'hidden md:block') })),
        React.createElement("div", { className: twMerge('flex md:flex-col grow md:grow-0 justify-between items-center gap-1 flex-wrap', !Icon && 'justify-center') },
            React.createElement("div", { className: 'font-bold text-md' }, title),
            badge,
            children && React.createElement("div", { className: "text-xs" }, children))));
};
