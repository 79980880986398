// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import Headline from '../../components/Headline';
import Tile from '../../components/tiles/Tile';
import LoadingIndicator from '../LoadingIndicator';
import track from '../../tracking';

import type MasterStore from '../../stores/MasterStore';
import type TilesStore from '../../stores/TilesStore';

type Props = {
  masterStore: MasterStore,
  tilesStore: TilesStore,
};

@inject('masterStore', 'tilesStore')
@observer
export default class PageFurtherInformation extends Component<Props> {
  static breadcrumb = 'Weitere Informationen';

  componentDidMount() {
    this.props.tilesStore.fetchAll();
    track.pageView(window.location.pathname, ['weitere-informationen'], 'category');
  }

  render() {
    const { masterStore, tilesStore } = this.props;
    const { masterData } = masterStore;
    const { ship } = masterData;

    if (tilesStore.isLoading) {
      return <LoadingIndicator />;
    }
    const { tiles } = tilesStore;

    return (
      <div className="page page-further-information">
        <div className="l-tiles">
          {tiles.anUndAbreiseinformationen.visible ? (
            <Tile
              full
              linkIsExternal
              badge={tiles.anUndAbreiseinformationen.notification}
              disabled={!tiles.anUndAbreiseinformationen.enabled}
              link="https://www.meinschiff.com/an-und-abreise"
              icon="infoTravel"
              title="An- & Abreiseinformationen"
            />
          ) : null}
          {tiles.faq.visible ? (
            <Tile
              full
              linkIsExternal
              badge={tiles.faq.notification}
              disabled={!tiles.faq.enabled}
              link="https://www.meinschiff.com/faq"
              icon="faq"
              title="Häufig gestellte Fragen (FAQ)"
            />
          ) : null}
          {tiles.informationenZuIhremSchiff.visible ? (
            <Tile
              full
              linkIsExternal
              badge={tiles.informationenZuIhremSchiff.notification}
              disabled={!tiles.informationenZuIhremSchiff.enabled}
              link={`https://www.meinschiff.com/${ship
                .toLowerCase()
                .replace(/ /g, '-')}/`}
              icon="infoShip"
              title="Informationen zu Ihrem Schiff"
            />
          ) : null}
          {tiles.gaestelisteAufFacebook.visible ? (
            <Tile
              full
              linkIsExternal
              badge={tiles.gaestelisteAufFacebook.notification}
              disabled={!tiles.gaestelisteAufFacebook.enabled}
              link="https://www.facebook.com/MeinSchiff/app_168076853256592?ref=page_internal"
              icon="fbGuests"
              title="Gästeliste auf Facebook"
            />
          ) : null}
          {tiles.verantwortungsvollesReisen.visible ? (
            <Tile
              full
              linkIsExternal
              badge={tiles.verantwortungsvollesReisen.notification}
              disabled={!tiles.verantwortungsvollesReisen.enabled}
              link="https://www.meinschiff.com/nachhaltigkeit"
              icon="environment"
              title="Verantwortungsvolles Reisen"
            />
          ) : null}
          {tiles.newsletter.visible ? (
            <Tile
              full
              linkIsExternal
              badge={tiles.newsletter.notification}
              disabled={!tiles.newsletter.enabled}
              link="https://www.meinschiff.com/newsletter"
              icon="envelope"
              title="Newsletter"
            />
          ) : null}
        </div>
      </div>
    );
  }
}
