export default class BookingResult {
  cancelable: boolean;
  contentId: string;
  type: string;
  displayType: string;
  displayName: string;
  displayDate: string;
  startDate: ?string;
  endDate: ?string;
  startTime: ?string;
  endTime: ?string;
  itemCount: number;
  seatCount: ?number;
  mpiBooker: ?string;
  mpis: Array<number>;
  bookingId: Object; // Or a more specific type, e.g. {[key: string]: string}
  prelimenary: boolean;
  typeId: string;
  apiId: string;
  cancellationNotAllowed: boolean;
  highlight: boolean;
  excludesFirstDay: ?boolean;
  excludesLastDay: ?boolean;
  subType: ?string;
  locationId: number;

  constructor(data: Object) {
    Object.assign(this, data);
  }
}
