// @flow
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import LoadingIndicator from '../../LoadingIndicator';
import Headline from '../../Headline';
import FlightPreferenceTile from '../../tiles/FlightPreferenceTile';
import track from '../../../tracking';
import type MasterStore from '../../../stores/MasterStore';
import type {
  FlightPreferenceStore,
  VipPartyMemberType,
  VipTexteType,
} from '../../../stores/FlightPreferenceStore';

type Props = {
  masterStore: MasterStore,
  flightPreferenceStore: FlightPreferenceStore,
};

@inject('flightPreferenceStore', 'masterStore')
@observer
export default class FlightVipGaesteView extends Component<Props> {
  static breadcrumb = 'VIP-Gäste';

  componentDidMount() {
    const { flightPreferenceStore } = this.props;
    flightPreferenceStore.status();
    this.trackPageView();
  }

  trackPageView = () => {
    track.pageView(window.location.pathname, ['buchung-reiseunterlagen', 'vip-tarif', 'vip-gaeste'], 'content');
  };

  render() {
    const { masterStore, flightPreferenceStore } = this.props;
    const { masterData } = masterStore;
    if (flightPreferenceStore.isLoading) return <LoadingIndicator />;

    const vipTravelParty: [VipPartyMemberType] =
      flightPreferenceStore.getVipTravelParty;

    const vipTexte: VipTexteType = flightPreferenceStore.getVipTexte;

    return (
      <div className="page vip-flight">
        <Headline title={vipTexte.headline || ''} />
        <div className="l-row">
          <div className="l-col double">
            <p>{vipTexte.subline || ''}</p>
          </div>
        </div>

        <section>
          <h1>Teilnehmer</h1>
          {vipTravelParty.map((member, index) => {
            const memberName = masterData.getPartyMember(member.mpi)
              .displayName;
            return (
              <div key={index}>
                <h2 className="l-mod-sub">{memberName}</h2>
                <div className="l-tiles">
                  <FlightPreferenceTile
                    travelParty={vipTravelParty}
                    partyMember={member}
                    state={member.status}
                  />
                </div>
              </div>
            );
          })}
        </section>
      </div>
    );
  }
}
