// @flow
import * as React from 'react';

import { Tile } from './NewTile';

import type PartyMember from '../../models/PartyMember';
import PaymentIcon from '../../../assets/svg/redesign/payment.svg';
import { Chip } from '../redesign/Chip';

type Props = {
  travelParty: PartyMember[],
  partyMember: PartyMember,
  linkBase?: string,
  state?: string,
  configEnabled: boolean,
  configNotification: string,
  onClick: (partyMember: PartyMember, url: string) => void,
};

const PaymentTile = ({ travelParty, partyMember, linkBase, configEnabled, configNotification, onClick }: Props) => {
  const index = travelParty.indexOf(partyMember);
  if (index === -1 || !partyMember.paymentAvailable) return null;
  let disabled = !partyMember.paymentAvailable || !partyMember.pinAvailable;

  let badge;
  if (partyMember.paymentPreliminary) {
    badge = <Chip>In Bearbeitung</Chip>;
  } else if (!partyMember.pinAvailable) {
    badge = <Chip>Schiffsmanifest unvollständig</Chip>;
  } else if (partyMember.paymentCompleted) {
    badge = <Chip mode="success">Vollständig</Chip>;
  } else {
    badge = <Chip>Keine Daten erfasst</Chip>;
  }

  const url = `${linkBase}/${index}`;

  // TUICUNIT-2856 if tilesconfig enable ignore other state and badge
  if (configEnabled === false) {
    disabled = true;
    badge = configNotification;
  }
  return (
    <Tile
      title="Zahlungsmittel"
      icon={PaymentIcon}
      badge={badge}
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault();
        if (!disabled && onClick) onClick(partyMember, url);
        return false;
      }}
    />
  );
};

export default PaymentTile;
