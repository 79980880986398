import React, { useEffect, useMemo, useRef, useState } from 'react';
import { tv } from 'tailwind-variants';
import { isEnterKey, isDeleteKey } from '../../utils/keys';
import EyeOn from '../../../assets/svg/redesign/eye-empty.svg';
import EyeOff from '../../../assets/svg/redesign/eye-off.svg';
var pinInputVariants = tv({
    slots: {
        root: 'flex justify-center',
        wrapper: 'flex gap-2 items-center shrink relative',
        inputBox: [
            'flex justify-center size-[58px] rounded-lg',
            'outline outline-1 outline-gray-300 bg-white',
            'text-[56px] leading-[46px] text-center',
        ],
        input: 'absolute -z-10 size-0 opacity-0 -top-32 -left-32 text-md',
        eye: 'size-6 cursor-pointer md:absolute md:-right-2 md:translate-x-full',
    },
    variants: {
        active: {
            true: {
                inputBox: 'outline-high-emphasis',
            },
        },
        showPin: {
            true: {
                inputBox: 'text-md items-center',
            },
        },
    },
});
export var PinInput = function (_a) {
    var length = _a.length, pin = _a.pin, onChange = _a.onChange, onEnter = _a.onEnter, className = _a.className;
    var classes = pinInputVariants();
    var ref = useRef(null);
    var _b = useState(false), showPin = _b[0], setShowPin = _b[1];
    var _c = useState(false), focused = _c[0], setFocused = _c[1];
    var EyeIcon = showPin ? EyeOn : EyeOff;
    var handleChange = function (e) {
        var value = e.currentTarget.value.toUpperCase();
        if (pin.length + value.length > length)
            return;
        onChange(pin + value);
    };
    var handleKeyUp = function (e) {
        switch (true) {
            case isEnterKey(e):
                onEnter === null || onEnter === void 0 ? void 0 : onEnter();
                break;
            case isDeleteKey(e):
                onChange(pin.slice(0, -1));
                break;
        }
    };
    var focusInput = function () { var _a; return (_a = ref.current) === null || _a === void 0 ? void 0 : _a.focus(); };
    var pinBlocks = useMemo(function () {
        var blocks = new Array(length).fill(0);
        return blocks.map(function (_, i) { return (React.createElement("div", { key: i, className: classes.inputBox({
                className: className,
                showPin: showPin,
                active: focused &&
                    // show active when this is the current block
                    (pin.length === i ||
                        // also show active when this is the last block
                        // and the pin is entered completely
                        (i + 1 === length && pin.length >= length)),
            }), onClick: function () { return focusInput(); } }, pin[i] ? (showPin ? pin[i] : '•') : '')); });
    }, [pin, showPin, focused, length]);
    useEffect(function () {
        if (!ref.current)
            return;
        var handleFocus = function () { return setFocused(true); };
        var handleBlur = function () { return setFocused(false); };
        ref.current.addEventListener('focus', handleFocus);
        ref.current.addEventListener('blur', handleBlur);
        ref.current.focus();
        return function () {
            ref.current.removeEventListener('focus', handleFocus);
            ref.current.removeEventListener('blur', handleBlur);
        };
    }, [ref.current]);
    return (React.createElement("div", { className: classes.root() },
        React.createElement("div", { className: classes.wrapper() },
            pinBlocks,
            (pin.length > 0 || showPin) && (React.createElement(EyeIcon, { className: classes.eye(), onClick: function () { return setShowPin(function (current) { return !current; }); } }))),
        React.createElement("input", { type: "text", value: "", className: classes.input(), onKeyUp: handleKeyUp, onChange: handleChange, ref: ref })));
};
