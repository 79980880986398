// @flow

import React, { Component } from 'react';

import Headline from '../Headline';
import PortalIntro from '../portal-intro/PortalIntroPin';

import CabinPresentsTeaser from '../CabinPresentsTeaser';
import AppStoreTeaser from '../AppStoreTeaser';
import type maintenanceStore from './../../stores/maintenanceStore';
import { inject, observer } from 'mobx-react';
import LoadingIndicator from '../LoadingIndicator';
import track from '../../tracking';

type Props = {
  maintenanceStore: maintenanceStore,
};

@inject('maintenanceStore')
@observer
export default class MaintenanceView extends Component<Props> {
  componentDidMount() {
    const { maintenanceStore } = this.props;
    maintenanceStore.fetch();
    track.pageView(window.location.pathname, ['maintenance'], 'content');
  }

  render() {
    const { maintenanceStore } = this.props;
    const { data } = maintenanceStore;
    if (maintenanceStore.isLoading) {
      return (
        <div className="vip-coupon">
          <LoadingIndicator />
        </div>
      );
    }
    return (
      <div className="page page-maintenance">
        <Headline title="Meine Reise derzeit nicht erreichbar" />
        <div className="l-row">
          <div className="l-col double">
            <div className="l-mod-sub">
              <PortalIntro />
            </div>
          </div>
          <div className="l-col right">
            <h2
              dangerouslySetInnerHTML={{
                __html: data.headline,
              }}
            ></h2>
            <p
              dangerouslySetInnerHTML={{
                __html: data.text,
              }}
            ></p>
          </div>
        </div>
        <div className="l-row">
          <div className="l-col double">
            <div className="l-row">
              <div className="l-col">
                <CabinPresentsTeaser />
              </div>
              <div className="l-col">
                <AppStoreTeaser />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
