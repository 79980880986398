//@flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import Tile from '../../components/tiles/Tile';
import track from '../../tracking';

import LoadingIndicator from '../LoadingIndicator';
import type TilesStore from '../../stores/TilesStore';

import { loadImg } from '../../utils/image';

type Props = {
  tilesStore: TilesStore,
};

@inject('tilesStore')
@observer
export default class PageNewspaperAndPackages extends Component<Props> {
  static breadcrumb = 'Pakete & Zeitungen';

  componentDidMount() {
    this.props.tilesStore.fetchAll();
    setTimeout(() => {
      this.trackPageView();
    }, 0);
  }

  trackPageView = () => {
    track.pageView(window.location.pathname, ['pakete & zeitungen'], 'category');
  };

  render() {
    const { tilesStore } = this.props;
    if (tilesStore.isLoading) {
      return <LoadingIndicator />;
    }
    const { tiles } = tilesStore;
    return (
      <div className="page">
        <div className="l-tiles">
          {tiles.pakete.visible ? (
            <Tile
              full
              badge={tiles.pakete.notification}
              disabled={!tiles.pakete.enabled}
              link="/pakete-und-zeitungen/pakete"
              imageSrc={loadImg('packages/package-544x324.jpg')}
              smallImageSrc={loadImg('packages/package-236x236.jpg')}
              title="Pakete"
            />
          ) : null}
          {tiles.zeitungen.visible ? (
            <Tile
              full
              badge={tiles.zeitungen.notification}
              disabled={!tiles.zeitungen.enabled}
              link="/pakete-und-zeitungen/zeitungen"
              imageSrc={loadImg('packages/newspaper-544x324.jpg')}
              smallImageSrc={loadImg('packages/newspaper-236x236.jpg')}
              title="Zeitungen"
            />
          ) : null}
        </div>
      </div>
    );
  }
}
