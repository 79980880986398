import React from 'react';
import { twMerge } from 'tailwind-merge';
import CancelIcon from '../../../assets/svg/redesign/cancel.svg';
export var Dialog = function (_a) {
    var title = _a.title, _b = _a.isOpen, isOpen = _b === void 0 ? false : _b, _c = _a.onClose, onClose = _c === void 0 ? function () { } : _c, children = _a.children;
    return (React.createElement("div", { className: twMerge('relative z-30', !isOpen && 'hidden'), role: "dialog", "aria-modal": "true" },
        React.createElement("div", { className: "fixed inset-0 z-30 bg-gray-500/75 transition-opacity" }),
        React.createElement("div", { className: "fixed inset-0 z-40 w-screen overflow-y-auto", onClick: function (e) {
                e.preventDefault();
                e.stopPropagation();
                onClose === null || onClose === void 0 ? void 0 : onClose();
            } },
            React.createElement("div", { className: "flex min-h-full items-end justify-center text-center md:items-center" },
                React.createElement("div", { className: "relative transform overflow-hidden rounded-lg bg-final-look text-left shadow-xl transition-all md:my-8 w-full md:max-w-lg", onClick: function (e) { return e.stopPropagation(); } },
                    React.createElement("div", { className: "flex flex-row gap-4 bg-white py-2.5 px-4 mb-6" },
                        React.createElement("div", { className: "w-9" }),
                        React.createElement("div", { className: "flex-grow text-center" },
                            React.createElement("h4", { className: "text-sm font-semibold text-high-emphasis" }, title)),
                        React.createElement("div", { className: "w-9 flex items-center justify-center" },
                            React.createElement("button", { "data-tailwind": true, className: "w-5 h-5", onClick: function (e) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onClose === null || onClose === void 0 ? void 0 : onClose();
                                } },
                                React.createElement(CancelIcon, { className: "w-5 h-5" })))),
                    React.createElement("div", { className: "pb-4 px-4" },
                        React.createElement("div", { className: "md:flex" },
                            React.createElement("div", { className: "mt-3 w-full text-center md:mt-0 md:text-left" },
                                React.createElement("div", { className: "mt-2" }, children)))))))));
};
