// @flow

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable, action } from 'mobx';
import { inject, observer } from 'mobx-react';

import LoadingIndicator from '../../LoadingIndicator';
import BreadcrumbLink from '../../breadcrumbs/BreadcrumbLink';
import PageLayout from '../../Spa/Layout';
import track from '../../../tracking';

import type SpaStore, { SpaCategoryStore } from '../../../stores/SpaStore';

type Params = { categoryId: string };

export default function spaCategory(division: string, headlineIcon: string) {
  @inject('spaStore')
  @observer
  class SpaCategoryBreadcrumb extends Component<{
    spaStore: SpaStore,
    params: Params,
  }> {
    static propTypes = {
      spaStore: PropTypes.object.isRequired,
      params: PropTypes.shape({
        categoryId: PropTypes.string.isRequired,
      }),
    };

    @observable categoryData: any = null;

    store: SpaCategoryStore;

    componentDidMount() {
      const { params, spaStore } = this.props;
      this.store = spaStore.storeForCategories(division);
      this.store.fetch(params.categoryId);
      spaStore.fetchCategoryData(params.categoryId).then(
        action((data) => {
          this.categoryData = data;
        })
      );
    }

    render() {
      if (!this.categoryData) return null;
      return (
        <BreadcrumbLink
          {...this.props}
          text={this.categoryData ? this.categoryData.name : this.props.params.categoryId}
        />
      );
    }
  }

  type Props = {
    spaStore: SpaStore,
    location: { pathname: string },
    params: Params,
  };

  @inject('spaStore', 'breadcrumbRouterStore')
  @observer
  class SpaCategory extends Component<Props> {
    static breadcrumb = SpaCategoryBreadcrumb;

    static propTypes = {
      spaStore: PropTypes.object.isRequired,
      location: PropTypes.object.isRequired,
      params: PropTypes.shape({
        categoryId: PropTypes.string.isRequired,
      }),
    };

    store: SpaCategoryStore;

    @observable categoryData: any = null;

    componentDidMount() {
      const { params, spaStore } = this.props;
      this.store = spaStore.storeForCategories(division);
      this.store.fetch(params.categoryId);
      spaStore.fetchCategoryData(params.categoryId).then(this.fetchedCategoryData);
      setTimeout(() => {
        this.trackPageView();
      }, 0);
    }

    trackPageView() {
      const { breadcrumbRouterStore } = this.props;
      const titles = breadcrumbRouterStore.getWithoutFirst;
      track.pageView(document.location.pathname, titles, 'sub-category');
    }

    @action.bound fetchedCategoryData(data: any) {
      this.categoryData = data;
    }

    render() {
      if (!this.categoryData) return <LoadingIndicator />;
      return (
        <PageLayout
          headline={this.categoryData.name}
          headlineIcon={headlineIcon}
          imageSrc={this.categoryData.imageSrc}
          description={this.categoryData.description}
          defaultHint={!!this.store.hint}
          store={this.store}
          linkBase={this.props.location.pathname}
        />
      );
    }
  }

  return SpaCategory;
}
